import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthProcessService } from 'ngx-auth-firebaseui';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { AngularFireAuth } from '@angular/fire/auth';
import { BackendService } from '../services/backend.service';
import { MatDialog } from '@angular/material/dialog';
import { CompanyAddDialogComponent } from '../company-add-dialog/company-add-dialog.component';
import { combineLatest, from, interval, Observable, of, Subject, Subscription } from 'rxjs';
import { Project } from '../projects/item/project.type';
import { debounce, filter, map, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { UiService } from '../services/ui.service';
import { Overlay } from '@angular/cdk/overlay';
import { NotificationComponent } from '../notification/notification.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  @ViewChild('supportInfo') supportInfo: ElementRef;
  @ViewChild('clickIcon') clickIcon: ElementRef;
  @ViewChild('termsDialog') termsDialog: any;
  dialogRef;
  isShowSupportInfo = false;
  public isMenuOpen = false;
  public projects$: Observable<Project[]>;
  public projects = [];
  public notifications = [];
  public isNew = 0;
  public subs: Subscription = new Subscription();
  public videoSubs: Subscription = new Subscription();


  get selected(): string {
    return this.uiService.selectedProjectId;
  }

  set selected(value: string) {
    this.projectInfo(value)
    this.uiService.selectedProjectId = value;
    if (value) {
      const project = this.projects.find(o => o.id == this.selected);
      const asset = this.uiService.allAssets.find(asset => asset.id == project.assetId)
      this.uiService.asset = asset;
    }
  }

  get selectedAssetNode() {
    return this.uiService.selectedAssetNode;
  }


  private onDestroy$ = new Subject();

  constructor(
    public readonly authProcess: AuthProcessService,
    private readonly router: Router,
    private readonly auth: AngularFireAuth,
    private readonly iconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    public backendService: BackendService,
    public dialog: MatDialog,
    private uiService: UiService,
    private renderer: Renderer2,
    private overlay: Overlay,
    private changeDetector: ChangeDetectorRef
  ) {
    const addIcon = name => {
      iconRegistry.addSvgIcon(
        name,
        sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${name}.svg`)
      );
    };
    addIcon('package');
    addIcon('members');
    addIcon('reports');
    addIcon('inbox');
    addIcon('reports_black');
    addIcon('tags');
    addIcon('logout');
    addIcon('logout_black');
    addIcon('upload');
    addIcon('trash');
    addIcon('add_member');
    addIcon('pencil_outline');
    addIcon('add_tag');
    addIcon('docx');
    addIcon('pdf');
    addIcon('add_note');
    addIcon('save');

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }


  listenProjects(): any {
    combineLatest(
      this.backendService.getAssets(),
      this.backendService.getCompanyAssets(),
      this.backendService.getProjects(),
      this.backendService.getCampaignList()
    )
      .pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(([assets, companyAssets, projects, campaigns]: any) => {
        this.projects = projects;
        this.uiService.allProjects = this.projects;
        this.uiService.allAssets = assets.filter(o => !o.deleted);
        this.uiService.allCompanies = companyAssets.filter(o => !o.deleted);;
        this.uiService.campaigns = campaigns;
        this.uiService.changeAssetOrProjectData$.next();
      });
  }

  showNotifications() {
    this.dialog.open(NotificationComponent, {
      width: '20vw',
      height: '100%',
      panelClass: 'bg-dialog',
      data: this.notifications
    });
  }

  ngOnInit(): void {
    this.backendService.refreshProjects();
    this.listenProjects();
    this.uiService.changeProjectEvent$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      if (this.uiService.selectedProjectId != this.uiService.projectInfo.projectId) {
        this.projectInfo(this.uiService.selectedProjectId)
      }
    });

    this.uiService.colorPaletteChange$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((data: any) => {
      if (this.uiService.projectInfo.projectId) {
        if (data.fetch && this.uiService.imageSubscription.closed) {
          this.getImages(this.uiService.projectInfo.projectId);
        }
        if (!data.fetch && !this.uiService.imageSubscription.closed
          && this.images.length) {
          this.uiService.imageSubscription.unsubscribe();
        }
      }
    })

    this.uiService.anomalyDetectionChange$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((data: any) => {
      if (this.uiService.projectInfo.projectId) {
        if (data.fetch && this.uiService.annotationSubscription.closed) {
          this.getAnnotations(this.uiService.projectInfo.projectId);
        }
        if (!data.fetch && !this.uiService.annotationSubscription.closed
          && this.annotations.length
        ) {
          this.uiService.annotationSubscription.unsubscribe();
        }
      }
    })

    /* if (this.checkUrlForProjectSelect()) {
       this.getProject();
     }
     this.router.events.pipe(filter(event => event instanceof NavigationEnd), takeUntil(this.onDestroy$)).subscribe((event) => {
 
       if (this.checkUrlForProjectSelect() && (!this.router.url.includes('images/'))) {
         this.getProject();
       }
     });
     */
    this.uiService.updateAssetNodeEvent$.subscribe((projects: any) => {
      this.getProject(projects);
    })

    const userSubscription = this.backendService.getUser(this.backendService.getCurrentUser().uid).pipe(take(1)).subscribe(user => {
      if (!user.data().industries || user.data().industries.length == 0) {
        this.router.navigate(["dashboard/user-profile"]);
      }
      this.backendService.getTerms().subscribe(terms => {
        let termsDate = terms.data().date?.toDate();
        if (user.data() && termsDate && (!user.data().termsAccepted || termsDate > user.data().termsAccepted.toDate())) {
          this.dialogRef = this.dialog.open(this.termsDialog, {
            width: '450px',
            disableClose: true,
            data: {
              termsDate: termsDate
            },
            scrollStrategy: this.overlay.scrollStrategies.noop(),
          });
          //this.dialogRef.updatePosition({ top: '10px' });   
        }
      })
      userSubscription.unsubscribe();


      this.backendService.getNotifications().pipe(
        takeUntil(this.onDestroy$)
      ).subscribe((notifications) => {
        this.notifications = notifications;
        this.isNew = this.notifications.filter(o => o.isRead != true).length;
      });
    });

    /* this.route.queryParams.subscribe((params) => {
       if (params.addToProject) {
         this.isAddingPeople=true;
         this.uiService.selectedProjectId = params.addToProject;
         const readonly = params.readonly === 'true' ? true : false;
         this.backendService.addProjectPeople(params.addToProject, params.role, readonly).subscribe((result) => {
           this.isAddingPeople=false;
           this.router.navigateByUrl(`dashboard/projects/${params.addToProject}`);
         }, error => {
           this.isAddingPeople=false;
         });
       }
     });
     */
  }

  ngAfterViewInit(): void {
    if (document) {
      this.renderer.listen(document, 'click', ($event) => {

        if (this.supportInfo &&
          $event.target !== this.supportInfo.nativeElement &&
          $event.target !== this.clickIcon.nativeElement
        ) {
          this.isShowSupportInfo = false;
        }
      });
    }

  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  logout(): void {
    this.backendService.tokenUpdate(this.backendService.getCurrentUser().uid, null).then(user => {
      this.authProcess.signOut().then(res => {
        //  this.router.navigate(['/']);
        document.location.reload();
      })
    })
  }

  openCompanyDialog(): void {
    this.dialog.open(CompanyAddDialogComponent, {
      disableClose: true
    });
  }

  public onSidenavClick(): void {
    this.isMenuOpen = false;
  }

  images = [];
  annotations = [];
  interval: Subscription;
  projectInfo(id) {
    if (this.uiService.projectInfo.projectId == id) {
      return;
    }
    if (!id) {
      this.clear(false)
      return
    }
    this.uiService.projectInfo.projectId =id; //cache this id to prevent multiple call
    /*this.subs = combineLatest(
      from(this.backendService.getProjectImages$(id)),
      from(this.backendService.getBatchProjectAnnotations(id))

    ).pipe(takeUntil(this.onDestroy$)).subscribe(([images, annotations]) => {
      this.clear(true);
      images.forEach((image, i) => {
        let filter = annotations.filter(o => o.imageId == image.id)[0];
        images[i].tags = filter && filter.polygons ? (filter as any).polygons : [];
        images[i].verifiedAnnotations = filter && filter.polygons ? (filter as any).verifiedAnnotations : false;
        images[i].isAIDetection = filter && filter.polygons ? (filter as any).isAIDetection : false;

        this.uiService.projectInfo.annotations = this.uiService.projectInfo.annotations + images[i].tags.length;
      });
      this.uiService.projectImageContexts = images;
      this.uiService.projectInfo.loading = false;
      this.uiService.projectInfo.images = images.length;
      this.uiService.projectInfo.annotatedImages = images.filter(o => o.tags && o.tags.length).length
      this.uiService.projectInfo.unAnnotatedImages = images.length - this.uiService.projectInfo.annotatedImages;
    })
    */

    //  this.annotations = [];
    this.clear(false);
    if (this.router.url.includes("projects")) {
      this.images = [];
      // Load all project images and annotations and data will be re-use on labels and other sections
      this.uiService.projectInfo.loading = true;
      this.getImages(id);
      this.getAnnotations(id);
    }

    /* this.subs = this.backendService.getProjectImages$(id).subscribe((images) => {
       this.uiService.projectInfo.projectId = id;
       if (images.length == 0) {
         this.clear(false);
       } else {
         this.getAnnotations(id, images);
       }
     });
     */
  }

  getImages(id) {
    this.uiService.imageSubscription.unsubscribe();
    this.uiService.imageSubscription = (this.backendService.getBatchProjectImages$(id))
      .pipe(takeUntil(this.onDestroy$)).subscribe((images: any) => {
        this.uiService.projectInfo.loading = false;
        if (!this.uiService.project) {
          const project = this.uiService.allProjects.find(o => o.id == id);
          if (project && (project.thermalColorConversionStatus === 'queued'
            || project.thermalColorConversionStatus === 'processing')) {
            this.uiService.imageSubscription.unsubscribe();
          }
        }
      this.uiService.projectImages=  this.images = images;
      });
  }

  getAnnotations(id) {
    this.uiService.annotationSubscription.unsubscribe();
    this.uiService.annotationSubscription = from(this.backendService.getBatchProjectAnnotations$(id)
      .pipe(takeUntil(this.onDestroy$))).subscribe((annotations: any) => {
        this.uiService.projectInfo.loading = false;
        this.uiService.projectInfo.projectId = id; //cache this id to prevent multiple call
        if (this.images.length) {
          if (!this.uiService.project) {
            const project = this.uiService.allProjects.find(o => o.id == id);
            if (project && (project.anomalyDetectionStatus === 'queued'
              || project.anomalyDetectionStatus === 'processing')) {
              this.uiService.annotationSubscription.unsubscribe();
            }
          }

          // Function to update image properties based on the annotation filter
          const updateImageProperties = (image, filter) => {
            image.tags = filter.polygons || [];
            image.thermalPolygons = filter.thermalPolygons || [];
            image.coldspots = filter.coldspots || [];
            image.hotspots = filter.hotspots || [];
            image.verifiedAnnotations = filter.polygons ? filter.verifiedAnnotations : false;
            image.isAIDetection = filter.polygons ? filter.isAIDetection : false;
          };


          // Create a mapping of imageId to annotation
          const annotationMapping = new Map();
          annotations.forEach(annotation => {
            annotationMapping.set(annotation.imageId, annotation);
          });


          // Update properties for this.images
          this.images.forEach((image, i) => {
            const filter = annotationMapping.get(image.id) || {};
            updateImageProperties(this.images[i], filter);
          });

          // Update properties for this.uiService.images
          this.uiService.images.forEach(image => {
            const filter = annotations.find(o => o.imageId === image.id) || {};
            updateImageProperties(image, filter);
          });

          this.uiService.projectImageContexts = this.images;
          //  this.uiService.projectInfo.loading = false;
          this.uiService.projectInfo.images = this.images.length;
          //  this.uiService.projectInfo.annotatedImages = this.images.filter(o => o.tags && o.tags.length).length;
          //  this.uiService.projectInfo.unAnnotatedImages = this.images.length - this.uiService.projectInfo.annotatedImages;

        }
      })

  }

  clear(loading) {
    this.uiService.projectInfo.images = 0;
    this.uiService.projectInfo.annotations = 0;
    this.uiService.projectInfo.annotatedImages = 0;
    this.uiService.projectInfo.unAnnotatedImages = 0;
    this.uiService.projectImageContexts = [];
    this.uiService.projectInfo.loading = loading;
  }


  /*getAnnotations(id, images) {
    if (this.annotationSubscription) {
      this.annotationSubscription.unsubscribe();
    }
    this.uiService.projectInfo.loading = true;
    this.backendService.cancelFetchAnnotations$.next();
    this.annotationSubscription = this.backendService.getBatchProjectAnnotations(id)
      .subscribe((annotations) => {
        this.clear(true);
        images.forEach((image, i) => {
          let filter = annotations.filter(o => o.imageId == image.id)[0];
          images[i].tags = filter && filter.polygons ? (filter as any).polygons : [];
          images[i].verifiedAnnotations = filter && filter.polygons ? (filter as any).verifiedAnnotations : false;
          images[i].isAIDetection = filter && filter.polygons ? (filter as any).isAIDetection : false;

          this.uiService.projectInfo.annotations = this.uiService.projectInfo.annotations + images[i].tags.length;
        });
        this.uiService.projectImageContexts = images;
        this.uiService.projectInfo.loading = false;
        this.uiService.projectInfo.images = images.length;
        this.uiService.projectInfo.annotatedImages = images.filter(o => o.tags && o.tags.length).length;
        this.uiService.projectInfo.unAnnotatedImages = images.length - this.uiService.projectInfo.annotatedImages;

      });
  }
*/
  getProject(projects): void {
    this.projects = projects;
    if (this.projects.length > 0) {
      if (!this.selected) {
        this.selected = this.projects[0].id;
      }
      else {
        if (!this.projects.find(o => o.id == this.selected)) {
          this.selected = this.projects[0].id;
        }
      }
      if (this.uiService.projectInfo.projectId != this.selected)
        this.projectInfo(this.selected);
    } else {
      this.selected = null;
    }
    this.uiService.getProjectsEvent$.next(projects);
  }

  checkUrlForProjectSelect(): boolean {
    return this.router.url.includes('members') || this.router.url.includes('tags') || this.router.url.includes('reports') || this.router.url.includes('projects/');
  }

  checkUrlForProjectDetailSelect(): boolean {
    return this.router.url.includes('projects/');
  }

  acceptTerms(data) {
    this.backendService.updateUser({ termsAccepted: data.termsDate, termsAcceptedCurrentTime: new Date() }).then(() => {
      this.dialogRef.close();
    })
  }

  declineTerms() {
    this.dialogRef.close();
    this.logout();
  }

  getShortName(name) {
    if (name) {
      const userName = name.split(' ');
      return `${userName[0].charAt(0).toString().toLocaleUpperCase()}  ${userName[1] ? userName[1].charAt(0).toString().toLocaleUpperCase() : userName[0].charAt(0).toString().toLocaleUpperCase()}`;
    }
    return "";
  }

}
