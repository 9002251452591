import { ImageTitleImageDialogComponent } from './image-title-image-dialog/image-title-image-dialog.component';
import { ImageDetailDialogComponent } from './image-detail-dialog/image-detail-dialog.component';
import { ImageTitlesDialogComponent } from './image-titles-dialog/image-titles-dialog.component';
import { ImageTitlesComponent } from './project-detail/image-titles/image-titles.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule  } from "@angular/material/core";
import { MatSelectModule } from '@angular/material/select';
import { MatTreeModule } from '@angular/material/tree';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatExpansionModule } from '@angular/material/expansion';

import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';

import { WaMatConfirmDialogModule } from '@webacad/material-confirm-dialog';
import { MatProgressButtonsModule } from 'mat-progress-buttons';

import { environment } from '../environments/environment';

import { KonvaModule } from './konva/konva.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LandingComponent } from './landing/landing.component';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProjectsComponent } from './projects/projects.component';
import { PageComponent } from './page/page.component';
import { ProjectsItemComponent } from './projects/item/item.component';
import { AuthInterceptor } from './auth.interceptor';
import { ProjectsAddDialogComponent } from './projects/add-dialog/add-dialog.component';
import { ProjectComponent } from './project/project.component';
import { ProjectAddFolderComponent } from './project/add-folder/add-folder.component';
import { ProjectRenameFolderComponent } from './project/rename-folder/rename-folder.component';
import { ProjectAddGroupComponent } from './project/add-group/add-group.component';
import { UploadComponent } from './upload/upload.component';
import { ImageComponent } from './image/image.component';
import { TaggingComponent } from './tagging/tagging.component';
import { TagSelectorComponent } from './tag-selector/tag-selector.component';
import { MembersComponent } from './members/members.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MemberAddDialogComponent } from './member-add-dialog/member-add-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TagsComponent } from './tags/tags.component';
import { TagAddDialogComponent } from './tag-add-dialog/tag-add-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { ReportsComponent } from './reports/reports.component';
import { CompanyAddDialogComponent } from './company-add-dialog/company-add-dialog.component';
import { MatSliderModule } from '@angular/material/slider';
import { TagNoteAddDialogComponent } from './tag-note-add-dialog/tag-note-add-dialog.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { ReportAddDialogComponent } from './report-add-dialog/report-add-dialog.component';
import { ReportAddImageDialogComponent } from './report-add-image-dialog/report-add-image-dialog.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ReportEditorDialogComponent } from './report-editor-dialog/report-editor-dialog.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DndModule } from 'ngx-drag-drop';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ImageGroupDialogComponent } from './image-group-dialog/image-group-dialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSort, MatSortModule} from '@angular/material/sort';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import { UploadTemplateComponent } from './upload-template/upload-template.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { GroupFilterPipe } from './shared/group.pipe';
import { SafeUrlPipe } from './shared/safe-url.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ImageCheckboxGridComponent } from './shared/image-checkbox-grid/image-checkbox-grid.component';
import { ReportGroupComponent } from './project/report-group/report-group.component';
import { ActionHandlerComponent } from './action-handler/action-handler.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ReportDocumentDialogComponent } from './reports/report-document-dialog/report-document-dialog.component';
import { NarrationBottomSheetComponent } from './tagging/narration-bottom-sheet/narration-bottom-sheet.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { HistoryBottomSheetComponent } from './history-bottom-sheet/history-bottom-sheet.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { IntlInputPhoneModule } from 'intl-input-phone';
import {SearchFilterPipe} from './reports/search-filter.pipe';
import { AddEditScopeComponent } from './add-edit-scope/add-edit-scope.component';
import { CKEditorModule } from 'ckeditor4-angular';
import {ConnectionServiceModule} from 'ng-connection-service';
import { AssetFormComponent } from './assets/asset-form/asset-form.component';
import { AssignComponent } from './assets/assign/assign.component';
import { CompareImagesComponent } from './compare-images/compare-images.component';   
import { NgxSliderModule } from "@angular-slider/ngx-slider";  
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AssetSidebarComponent } from './shared/asset-sidebar/asset-sidebar.component';
import { FullCalendarModule } from '@fullcalendar/angular'; 
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { ImageTimelineDialogComponent } from './image-timeline-dialog/image-timeline-dialog.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ProjectInitialsComponent } from './project-initials/project-initials.component';
import { MatVideoModule } from 'mat-video';
import { VideoModalComponent } from './video-modal/video-modal.component';
//import { StlModelViewerModule } from 'angular-stl-model-viewer';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { LinkImagesComponent } from './link-images/link-images.component';
import { ImageModalComponent } from './image-modal/image-modal.component';
import { BaseModelComponent } from './base-model/base-model.component';
import { BaseImageModelComponent } from './base-image-model/base-image-model.component';
import { TagFilterComponent } from './tagging/tag-filter/tag-filter.component';
import { TermsComponent } from './terms/terms.component';
import { LinkLabelsComponent } from './tagging/link-labels/link-labels.component';
import { LabelGridComponent } from './shared/label-grid/label-grid.component';
import { FilterComponent } from './project/filter/filter.component';
import { DragulaModule } from 'ng2-dragula';
import { GroupFilterComponent } from './compare-images/group-filter/group-filter.component';
//import { AssetSummaryComponent } from './asset-summary/asset-summary.component';
import { AlignModelsComponent } from './align-models/align-models.component';
import { NotificationComponent } from './notification/notification.component';
import { SeverityLevelComponent } from './tagging/severity-level/severity-level.component';
import { AddCampaignDialogeComponent } from './projects/add-campaign/add-campaign.component';
import { ColorPlateComponent } from './project-detail/color-plate/color-plate.component';
import { DxfLayersComponent } from './dxf-layers/dxf-layers.component';
import { CommonModule } from '@angular/common';
import { SolarCsvColumnsComponent } from './solar-csv-columns/solar-csv-columns.component';
import { BoundingBoxComponent } from './bounding-box/bounding-box.component';
FullCalendarModule.registerPlugins([ 
 interactionPlugin,
  dayGridPlugin, //month
  listPlugin,
  timeGridPlugin //week
]);
@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    SigninComponent,
    SignupComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    ProjectsComponent,
    PageComponent,
    ProjectsItemComponent,
    ProjectsAddDialogComponent,
    ProjectComponent,
    ProjectAddFolderComponent,
    ProjectAddGroupComponent,
    ProjectRenameFolderComponent,
    UploadComponent,
    ImageComponent,
    TaggingComponent,
    TagSelectorComponent,
    MembersComponent,
    MemberAddDialogComponent,
    TagsComponent,
    TagAddDialogComponent,
    ReportsComponent,
    CompanyAddDialogComponent,
    TagNoteAddDialogComponent,
    ReportAddDialogComponent,
    ReportAddImageDialogComponent,
    ReportEditorDialogComponent,
    ImageGroupDialogComponent,
    ImageDetailDialogComponent,
    ImageTitlesDialogComponent,
    ImageTitleImageDialogComponent,
    UploadTemplateComponent,
    ProjectDetailComponent,
    GroupFilterPipe,
    ImageCheckboxGridComponent,
    ReportGroupComponent,
    ActionHandlerComponent,
    VerifyEmailComponent,
    ResetPasswordComponent,
    ImageTitlesComponent,
    SafeUrlPipe,
    ReportDocumentDialogComponent,
    NarrationBottomSheetComponent,
    UserProfileComponent,
    UpdatePasswordComponent,
    HistoryBottomSheetComponent,
    SearchFilterPipe,
    AddEditScopeComponent,
    AssetFormComponent,
    AssignComponent,
    CompareImagesComponent,
    AssetSidebarComponent,
    ImageTimelineDialogComponent,
    ProjectInitialsComponent,
    VideoModalComponent,
    LinkImagesComponent,
    ImageModalComponent,
    BaseModelComponent,
    BaseImageModelComponent,
    TagFilterComponent,
    TermsComponent,
    LinkLabelsComponent,
    LabelGridComponent,
    FilterComponent,
    GroupFilterComponent,
   // AssetSummaryComponent,
    AlignModelsComponent,
    NotificationComponent,
    SeverityLevelComponent,
    AddCampaignDialogeComponent,
    ColorPlateComponent,
    DxfLayersComponent,
    SolarCsvColumnsComponent,
    BoundingBoxComponent,
  //  StlModelViewerModule

  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    KonvaModule,
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatProgressBarModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatProgressButtonsModule.forRoot(),
    MatTreeModule,
    MatListModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatCheckboxModule,
    MatMenuModule,
    MatTabsModule,
    MatSortModule,
    WaMatConfirmDialogModule,
    MatVideoModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgxAuthFirebaseUIModule.forRoot(environment.firebase, () => 'dive', {
      enableFirestoreSync: true,
      toastMessageOnAuthSuccess: false,
      toastMessageOnAuthError: true,
      authGuardFallbackURL: '/signin',
      authGuardLoggedInURL: '/dashboard',
    }),
    AngularFireStorageModule,
    NgbModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatRadioModule,
    MatSidenavModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatExpansionModule,
    DndModule,
    NgxDnDModule.forRoot(),
    TreeModule,
    DragDropModule,
    DragToSelectModule.forRoot(),
    InfiniteScrollModule,
    ToastrModule.forRoot(),
    IntlInputPhoneModule,
    CKEditorModule,
    ConnectionServiceModule,
    NgxSliderModule,
    NgSelectModule,
    PinchZoomModule,
    MatCarouselModule,
    PdfViewerModule,
    FullCalendarModule,
    NgxSkeletonLoaderModule.forRoot(),
    ClipboardModule,
    DragulaModule.forRoot()
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    BnNgIdleService,
    MatSort
  ],
  schemas: [NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }