import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { UiService } from './services/ui.service';
import * as moment from 'moment';
import { ConnectionService } from 'ng-connection-service';
import { BackendService } from './services/backend.service';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'dive';
  isUserLoggedIn: boolean = false;
  user: any;
  interval: any;
  noInternetConnection: boolean = false;
  userSubsription: Subscription;

  constructor(
    private backend: BackendService, private uiService: UiService, public authProcess: AngularFireAuth, private router: Router, private connectionService: ConnectionService) {
    const auth = authProcess;
    auth.onAuthStateChanged(async (user) => {
      if (this.interval) {
        clearInterval(this.interval)
      }
      if (!sessionStorage.getItem('sessionLogout')) {
        sessionStorage.setItem('sessionLogout', new Date(moment().endOf('week').toDate()).getTime().toString())
      }

      this.user = user;
      if (user === null) {
        this.isUserLoggedIn = false;
        if (this.userSubsription) {
          this.userSubsription.unsubscribe();
        }
      } else {
        const idToken = await user.getIdToken();
        if (this.userSubsription) {
          this.userSubsription.unsubscribe();
        }
        this.userSubsription = this.backend.getUser$(user.uid).subscribe((user) => {
          if (user) {
            if (user.idToken && !this.uiService.isUploadImages && !this.uiService.isUploadImages && user.idToken != idToken) {
              sessionStorage.setItem("inactiveUser", "true");
              // instantly logout when new session created
           //   this.logout();
            }
          }
        })
     //   this.backend.tokenUpdate(this.user.uid, idToken);
        if (sessionStorage.getItem('sessionLogout')) {
          this.interval = setInterval(() => {
            if (
              parseInt(sessionStorage.getItem('sessionLogout')) <= new Date().getTime()
              && !this.uiService.isUploadImages
            )
            // every weekend logout user
              this.logout();
          }, 10000);
        }
        this.isUserLoggedIn = true;
      }
    })

    this.connectionService.monitor().subscribe(isConnected => {
      if (isConnected) {
        this.noInternetConnection = false;
      }
      else {
        this.noInternetConnection = true;
      }
    })
  }

  logout() {
    this.backend.tokenUpdate(this.user.uid, null).then(user => {
      this.authProcess.signOut().then(res => {
        document.location.reload();
      })
    })

  }
}