import { Component, Input, ViewChild, Inject, OnDestroy, OnInit, Output, EventEmitter, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';
import { BackendService } from '../services/backend.service';
import { MatDialog } from '@angular/material/dialog';
import { LinkImagesComponent } from '../link-images/link-images.component';
import { UiService } from '../services/ui.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Subject, BehaviorSubject, interval } from 'rxjs';
import { ImageModalComponent } from '../image-modal/image-modal.component';
import { v4 as uuidv4 } from 'uuid';
import { VideoModalComponent } from '../video-modal/video-modal.component';
import { ToastrService } from 'ngx-toastr';
import { filter, map, takeUntil, takeWhile } from 'rxjs/operators';
import { KonvaComponent } from '../konva/konva.module';
import { Images, KonvaConfig, TaggedArea } from '../models/tagging.interface';
import Konva from 'konva';
import { DXFViewer } from '../Three/dxf-viewer';
import { DXF, Status } from './../models/app.enum';
import { ITreeOptions, ITreeState, TreeComponent, TreeModel, TREE_ACTIONS } from '@circlon/angular-tree-component';
const props = "children";
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { DxfLayersComponent } from '../dxf-layers/dxf-layers.component';
import { WaMatConfirmDialog } from '@webacad/material-confirm-dialog';
import { GeneralService } from '../services/general.service';
import * as THREE from 'three'

function getRelativePointerPosition(node: Konva.Node): Konva.Vector2d {
  const transform = node.getAbsoluteTransform().copy();
  transform.invert();
  const pos = node.getStage().getPointerPosition();
  return transform.point(pos);
}

@Component({
  selector: 'app-base-image-model',
  templateUrl: './base-image-model.component.html',
  styleUrls: ['./base-image-model.component.scss']
})
export class BaseImageModelComponent implements OnInit, OnDestroy {
  @Input() public assetId;
  @Input() public asset;
  @Input() public dialogRef;
  @Input() public isAssetOwner;
  @Input() public projects;
  @Input() public selectedProjectId;
  @Input() public leftProjectId;
  @Input() public rightProjectId;
  @Input() public leftImageId;
  @Input() public rightImageId;
  @Input() public panelsVisible;
  @Input() public type = 'asset';
  is2DPanelExpand: boolean = false;
  @Output() selectionChange: EventEmitter<any> = new EventEmitter();
  @Output() cancelSelection: EventEmitter<any> = new EventEmitter();

  @ViewChild('dxfTreeComponent') private treeComponent: TreeComponent;
  @ViewChild('deleteLabelDialog') deleteLabelDialog: any;
  deleteLabelDialogRef;
  @ViewChild('renameDialog') renameDialog: any;
  renameDialogRef;
  @ViewChild('georeference') georeferenceDialog: any;
  georeferenceDialogRef: any;
  @ViewChild('dxfDialog') dxfDialog: any;
  dxfDialogRef: any;
  @ViewChild('geoPointsDialog') geoPointsDialog: any;
  geoPointsDialogRef: any;
  @ViewChild('panelLayerDailog') panelLayerDailog: any;
  panelLayerDailogRef: any;
  @ViewChild('progressBar') progressBar: any;
  @ViewChild('levelDialog') levelDialog: any;
  levelDialogRef;
  public isImageUploading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  baseFile: boolean = false;
  projectId: string;
  annotations = [];
  linkImages;
  technicalDrawings = [];
  drawing;
  private onDestroy$ = new Subject();
  linkImageSubscription: any = new Subject();
  public isLeftMenu: boolean = false;
  public isRightMenu: boolean = false;
  @ViewChild('containerImage') container: ElementRef;
  @ViewChild('stage') stage: KonvaComponent;
  leftImageSubscription: Subscription;
  rightImageSubscription: Subscription;
  panelSubscription: Subscription;

  public configStage$: BehaviorSubject<any> = new BehaviorSubject({
    width: 0,
    height: 0,
  });
  public containerSize: any;
  public configImg$: BehaviorSubject<KonvaConfig> = new BehaviorSubject({
    width: 0,
    height: 0,
    image: null,
  });
  public taggedAreas: Map<string, TaggedArea[]> = new Map();

  public get activeImgAreas(): TaggedArea[] {
    return this.taggedAreas.get(this.drawing?.id) || [];
  }
  public get queued() {
    return Status.QUEUED;
  }
  public get processing() {
    return Status.PROCESSING;
  }
  public get failed() {
    return Status.FAILED;
  }
  public get success() {
    return Status.SUCCESS;
  }
  isPanelDXF: boolean = false;
  labelsChanged: boolean = false;
  treeOptions: ITreeOptions = {
    allowDrag: false,
    allowDrop: false
  };
  nodes: any = [];
  state: ITreeState = {
    expandedNodeIds: {
      1: true,
      2: true
    },
    hiddenNodeIds: {},
    activeNodeIds: {}
  };

  public createBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Save',
    raised: true,
    spinnerSize: 24,
    spinnerColor: 'primary',
    buttonIcon: {
      color: 'primary',
      fontIcon: 'save',
      inline: false
    },
    customClass: 'text-uppercase'
  };
  levels = {
    'L1': 'L1',
    'L2': 'L2',
    'L3': 'L3',
    'L4': 'L4',
    'L5': 'L5',
  }
  constructor(
    private dialog: MatDialog,
    public uiService: UiService,
    public backendService: BackendService,
    private toaster: ToastrService,
    private renderer: Renderer2,
    private generalService: GeneralService,
    private confirmDialog: WaMatConfirmDialog,
    private router: Router) {

  }

  setContainerSize(): void {
    if (this.container) {
      const { offsetWidth, offsetHeight } = this.container.nativeElement;
      this.containerSize = {
        width: offsetWidth,
        height: offsetHeight,
      };
      this.configStage$.next(this.containerSize);
      this.configImg$.next(this.containerSize);
    }
  }

  handleZoom(event: WheelEvent): void {
    if (!this.configImg$.getValue().image) {
      return;
    }

    //this.taggedAreas.set(this.drawing.id, this.imageAnnotations);
  }

  getImageScale(drawing: any): { widthScale: number; heightScale: number } {
    if (!drawing || !drawing.image) {
      return {
        widthScale: 1,
        heightScale: 1
      };
    };
    const image = drawing.image;
    const { width, height } = image;
    const { width: stageWidth, height: stageHeight } = this.configStage$.getValue();
    const widthScale = stageWidth / width;
    const heightScale = stageHeight / height;
    return {
      widthScale: Math.min(widthScale, heightScale),
      heightScale: Math.min(widthScale, heightScale),
    };
  }

  activate(drawing): void {
    const { image } = this.drawing;
    this.setContainerSize();

    if (image) {
      const { height, width } = image;
      const { widthScale, heightScale } = this.getImageScale(drawing);
      const newWidth = width * widthScale;
      const newHeight = height * heightScale;
      this.getLabels(drawing.id);
      this.stage.reset();
      const newSize = {
        width: newWidth,
        height: newHeight,
      };
      this.configStage$.next(newSize);
      this.configImg$.next({
        ...newSize,
        image: image,
      });

    }
    else {
      const image = new Image();
      image.onload = () => {
        this.drawing['image'] = image;
        const { height, width } = image;
        const { widthScale, heightScale } = this.getImageScale(drawing);
        const newWidth = width * widthScale;
        const newHeight = height * heightScale;
        this.getLabels(drawing.id);
        this.stage.reset();
        const newSize = {
          width: newWidth,
          height: newHeight,
        };
        this.configStage$.next(newSize);
        this.configImg$.next({
          ...newSize,
          image: image
        });
      };
      image.src = drawing.fileUrl;
    }
  }

  handleClick(event): void {
    if (this.isAssetOwner && this.type != 'report') {
      if (event.target.attrs.name) {
        const label = this.annotations.find(o => o.id == event.target.attrs.name)
        this.gotoAnnotation(label, true)
      } else {
        this.handleMouseEvent(event);
      }
    }
  }

  handleMouseEvent(event: MouseEvent): void {
    try {
      // avoid breaking when event is a WheelEvent
      if (event['evt'].shiftKey) return;
    } catch (error) {
      // do nothing
    }
    const point = getRelativePointerPosition(this.stage.getStage());
    const { widthScale, heightScale } = this.getImageScale(this.drawing);
    const pixels = {
      x: point.x * (1 / widthScale),
      y: point.y * (1 / heightScale),
    }

    let labelTitle = prompt('Please enter label number');

    if (!labelTitle) {
      return;
    }
    if ([].concat(...this.technicalDrawings.map(o => o.labels)).find(o => o?.title === labelTitle)) {
      this.toaster.warning("Label name should be unique. You can not create the duplicate label");
      return;
    }
    const label = [{
      "title": labelTitle || "",
      "id": uuidv4(),
      "point": {
        "x": pixels.x,
        "y": pixels.y
      }
    }]
    this.backendService.createTechnicalDrawingLabels(this.drawing, label).subscribe((result) => { })
  }

  ngOnInit(): void {
    console.log(this.type);
    if (this.type === 'timeline') {
      // this.is2DPanelExpand = true;
    }
    if (this.type === "project" && this.selectedProjectId) {
      this.projectId = this.selectedProjectId;
    } else {
      if (this.asset.baselineProjectId) {
        this.projectId = this.asset.baselineProjectId;

      }
    }
    this.getModels();
  }

  isSetConfigStage = true;
  public isSetContainerSize: boolean = true;
  ngAfterViewInit() {
    if (window) {
      this.renderer.listen(window, 'resize', () => {
        this.isSetConfigStage = true;
      });
    }
  }

  ngAfterViewChecked(): void {
    if (this.isSetConfigStage && this.container) {
      this.isSetConfigStage = false;
      this.setContainerSize();
      if (this.drawing) {
        this.activate(this.drawing);
      }
    }

    if (this.container && this.isSetContainerSize) {
      this.isSetContainerSize = false;
      const { offsetWidth, offsetHeight } = this.container.nativeElement;
      this.containerSize = {
        width: offsetWidth,
        height: offsetHeight,
      };
    }
  }
  modelSubscription: Subscription;
  dxfFile: any;
  isProcessing: boolean = false;
  getModels() {
    this.isProcessing = true;
    if (this.asset.assetType !== 'solar') {
      this.modelSubscription = this.backendService.get2DModels$(this.assetId).pipe(takeUntil(this.onDestroy$)).subscribe((result: any) => {
        this.isProcessing = false;
        result = result.filter(o => !o.isDXFFile);
        if (result.length) {
          this.technicalDrawings = result;
          if (result) {
            this.baseFile = true;
            if (!this.drawing) {
              this.drawing = result[0];
              this.activate(this.drawing);
            } else {
              this.drawing = { ...this.drawing, ...this.technicalDrawings.find(o => o.id == this.drawing.id) }
            }
          }
        }
        else {
          this.baseFile = false;
        }
      });

    } else {
      this.modelSubscription = this.backendService.get2DDXFModels$(this.assetId).pipe(takeUntil(this.onDestroy$)).subscribe((result: any) => {
        this.isProcessing = false;
        if (result) {
          this.baseFile = true;
          this.dxfFile = result;
          if (this.dxfFile.levels) {
            this.levels = this.dxfFile.levels;
          }
          if (!this.viewer) {
            this.initDXF(this.dxfFile);
          }
          if (this.dxfFile.dxfStatus === Status.SUCCESS && this.dxfFile.dxfUrl) {
            /* if (!this.dxfFile.isGeoreferenced && !this.dxfFile.manualLinking) {
               this.geoPointsDialogRef = this.dialog.open(this.geoPointsDialog, {
                 disableClose: true,
               });
             }*/

            if (!this.isPanelDXF) {
              if (this.uiService.dxfId === this.dxfFile.id && this.uiService.panelDXF) {
                this.isPanelDXF = true;
                this.viewer.addPanelsToScene(this.uiService.panelDXF)
                if (this.projectId) {
                  this.getDXFLinkedImages(this.dxfFile.id);
                }
                if (this.leftProjectId) {
                  this.getDXFLeftLinkedImages(this.dxfFile.id);
                }
                if (this.rightProjectId) {
                  this.getDXFRightLinkedImages(this.dxfFile.id);
                }
              } else {
                this.backendService.getFile(this.dxfFile.dxfUrl).subscribe((panels: any) => {
                  this.isPanelDXF = true;
                  this.viewer.renderDxf(panels);
                  if (this.projectId) {
                    this.getDXFLinkedImages(this.dxfFile.id);
                  }
                  if (this.leftProjectId) {
                    this.getDXFLeftLinkedImages(this.dxfFile.id);
                  }
                  if (this.rightProjectId) {
                    this.getDXFRightLinkedImages(this.dxfFile.id);
                  }
                })
              }
              this.getDXFLabels(this.dxfFile);

            }
          }
        }
      })
    }
  }

  carausalActivateImage(drawing) {
    this.drawing = drawing;
    this.selectedLabel = null;
    this.linkImages = null;
    this.activate(drawing);
  }

  selectedLabel;
  gotoAnnotation(node, isShow) {
    this.selectedLabel = node;
    if (isShow) {
      this.addImages(node, this.drawing.id)
    }
  }


  addImages(el, modelId): void {
    if (!this.isAssetOwner) {
      return;
    }
    if (this.projects.length == 0) {
      this.toaster.warning('Add the poject first.')
      return;
    }
    if (!this.asset.baselineProjectId) {
      this.toaster.warning('Choose your baseline projects from asset panel first.')
      return;
    }
    const baseLineProject = this.projects.find(o => o.id == this.asset.baselineProjectId);
    if (!baseLineProject) {
      this.toaster.warning('You are not able to access your baseline project.')
      return;
    }

    const index = this.annotations.findIndex(o => o.id == el.id);
    if (!this.linkImages[el.id]) {
      this.linkImages[el.id] = [];
    }
    const nodeImagesIds = this.linkImages[el.id].map(item => item.id) || [];
    const data: any = {
      project: baseLineProject,
      nodeImagesIds,
      assetId: this.assetId,
      label: this.annotations[index].title
    };
    const dialogRef = this.dialog.open(LinkImagesComponent, {
      width: '70vw',
      height: '100vh',
      panelClass: 'no-border-radius-dialog',
      data,
    });
    dialogRef.afterClosed().subscribe(r => {
      if (!r) {
        return;
      }
      if (r.images.length) {
        this.backendService.linkImages(this.assetId, modelId, this.annotations[index].id, r.images, "images", this.asset.baselineProjectId, "2d").subscribe((result) => {
          r.images.forEach(imageId => {
            if (imageId && !this.linkImages[el.id].find(o => o.id == imageId)) {
              this.backendService.getImage$<any>(imageId).subscribe((image: any) => {
                if (image.deleted != true) {
                  this.linkImages[el.id].push(image)
                }
              });
            }
          });
        });
      }
      if (r.videos.length) {
        this.backendService.linkImages(this.assetId, modelId, this.annotations[index].id, r.videos, "videos", this.asset.baselineProjectId, "2d").subscribe((result) => {
          r.videos.forEach(videoId => {
            if (videoId && !this.linkImages[el.id].find(o => o.id == videoId)) {
              this.backendService.getVideo$<any>(videoId).subscribe((video: any) => {
                if (video.deleted != true) {
                  this.linkImages[el.id].push(video)
                }
              });
            }
          });
        });
      }

    });
  }

  labelSubscription: Subscription;
  getLabels(id) {
    //const circleTexture = new THREE.TextureLoader().load('assets/model/circle.png')
    if (this.labelSubscription) {
      this.labelSubscription.unsubscribe();
    }
    this.labelSubscription = this.backendService.getTechnicalDrawinglLabels$(id).subscribe((result: any) => {
      this.annotations = result.label || [];
      const annotations = [];
      const index = this.technicalDrawings.findIndex(o => o.id == id)
      this.technicalDrawings[index].labels = this.annotations;
      const { widthScale, heightScale } = this.getImageScale(this.drawing);
      this.annotations.forEach((label, i) => {
        const pixel = {
          x: label.point.x / (1 / widthScale),
          y: label.point.y / (1 / heightScale),
        }
        annotations.push(
          {
            config$: new BehaviorSubject({
              x: pixel.x,
              name: label.id,
              y: pixel.y,
              width: 25,
              height: 25,
              stroke: '#fff',
              fill: 'rgba(103, 95, 95, 0.8)',
              strokeWidth: 1
            }),
            configText$: new BehaviorSubject({
              name: label.id,
              x: (i + 1).toString().length > 1 ? pixel.x - 6 : pixel.x - 2,
              y: pixel.y - 5,
              text: i + 1,
              fill: '#FFFFFF',
              fontSize: 12,
              lineHeight: 1,
              align: 'center'
            })
          })
      })
      this.taggedAreas.set(this.drawing.id, annotations);
      if (!this.linkImages) {
        this.getLinkedImages(this.drawing.id);
      }
    });
  }

  getLinkedImages(id) {
    if (this.linkImageSubscription) {
      this.linkImageSubscription.unsubscribe();
    }
    this.linkImages = [];
    this.linkImageSubscription = this.backendService.getLinkedImages(this.assetId, id, this.asset.baselineProjectId).subscribe((result) => {
      if (!result) { return }
      let keys = Object.keys(result);
      const _this = this;
      keys.forEach(element => {
        const index = this.annotations.findIndex(o => o.id == element);
        if (index != -1) {
          if (!_this.linkImages[element]) {
            _this.linkImages[element] = [];
          }
          result[element]['images']?.forEach(image => {
            if (image && !this.linkImages[element].find(o => o.id == image)) {
              this.backendService.getImage$<any>(image).subscribe((image: any) => {
                if (image.deleted != true) {
                  _this.linkImages[element].push(image)
                }
              });
            }
          })
          result[element]['videos']?.forEach(videoId => {
            if (videoId && !this.linkImages[element].find(o => o.id == videoId)) {
              this.backendService.getVideo$<any>(videoId).subscribe((video: any) => {
                if (video.deleted != true) {
                  _this.linkImages[element].push(video)
                }
              });
            }
          })
        }
      });
    });
  }


  linkedMedia = [];
  getDXFLinkedImages(modelId: string) {
    this.linkedMedia = null;
    this.labelSubscription = this.backendService
      .getDXFLinkedImages(this.assetId, modelId, this.projectId).subscribe((linkImages: any) => {
        if (linkImages && linkImages.linkFile) {
          this.backendService.getFile(linkImages.linkFile).subscribe((medias: any) => {
            this.linkedMedia = JSON.parse(medias) || [];
          })
        }
      }, (error) => {
        throw error;
      });
  }

  leftLinkedMedia = null;
  leftImage;
  getDXFLeftLinkedImages(modelId: string) {
    this.leftLinkedMedia = null;
    this.labelSubscription = this.backendService
      .getDXFLinkedImages(this.assetId, modelId, this.leftProjectId).subscribe((linkImages: any) => {
        if (linkImages && linkImages.linkFile) {
          this.backendService.getFile(linkImages.linkFile).subscribe((medias: any) => {
            this.leftLinkedMedia = JSON.parse(medias) || [];
            if (this.panelsVisible && this.leftImageId) {
              const observable = interval(500);
              this.leftImageSubscription = observable.pipe(
                filter(() => {
                  return this.nodes.length && this.viewer && this.viewer.scene.children.filter(o => o.name === 'PanelViewer' || o.name === 'DXFViewer').length == 2;

                })
              ).subscribe(() => {
                this.leftImageSubscription.unsubscribe();
                this.backendService.getImage$<any>(this.leftImageId).subscribe((image: any) => {
                  if (image.deleted != true) {
                    this.leftImage = image;
                  }
                });
                this.markedLabels(this.leftImageId, this.leftLinkedMedia, 0XFFA500, 'left')


              });

            }
          })
        }
      }, (error) => {
        throw error;
      });
  }

  markingPanels = [];
  highlightedImageId;
  markedLabels(imageId, mediaFile, color, type) {
    function findPanelsByImage(imageId) {
      const panels = [];
      for (const panelId in mediaFile) {
        if (mediaFile[panelId] && mediaFile[panelId].images && mediaFile[panelId].images.includes(imageId)) {
          panels.push(panelId);
        }
      }
      return panels;
    }
    const resultPanels = findPanelsByImage(imageId);

    function getHandlesByLayer(data, labels, layer = 'L5') {
      const panels = [];

      function search(tree) {
        if (tree.layer === layer && labels.find(id => id === tree.id) && tree.panels) {
          panels.push(...tree.panels);
        }

        if (tree.children) {
          tree.children.forEach(child => search(child));
        }
      }

      data.forEach(item => search(item));

      return panels;
    }
    const handles = getHandlesByLayer(this.nodes, resultPanels, 'L5');
    if (!handles.length) {
      this.toaster.warning(!this.rightImageId ? "Image not linked with any panels" :
        type == 'right' ? "Right Image not linked with any panels" :
          "Left Image not linked with any panels"
      );
      return;
    }
    const panelLayer = this.viewer.scene.children.filter(o => o.name === 'PanelViewer');
    if (panelLayer.length) {
      const markingPanels = {
        imageId: imageId,
        visible: this.markingPanels.length == 0 ? true : false
      }
      if (markingPanels.visible) {
        this.highlightedImageId = imageId;
      }
      const layers = panelLayer[0].children.filter(layer =>
        handles.filter(this.generalService.onlyUnique).includes(layer.userData.entity.handle));
      if (markingPanels.visible) {
        this.handleResize();
      }
      this.viewer.createGroup(imageId, layers.map(o => o.userData.entity), color, true, markingPanels.visible)
      this.markingPanels.push(markingPanels)
    }
  }

  markedImage(imageId) {
    if (this.highlightedImageId === imageId) {
      // inverse highlight
      const highlight = this.viewer.scene.children.find(o => o.name === 'shapeLayers' && o.userData.id != imageId);
      const unHighlight = this.viewer.scene.children.find(o => o.name === 'shapeLayers' && o.userData.id === imageId);
      unHighlight.visible = false;
      highlight.visible = true;
      this.highlightedImageId = highlight.userData.id;
      this.viewer.setCameraToPanel(highlight.children);
    } else {
      const unHighlight = this.viewer.scene.children.find(o => o.name === 'shapeLayers' && o.userData.id != imageId);
      const highlight = this.viewer.scene.children.find(o => o.name === 'shapeLayers' && o.userData.id === imageId);
      unHighlight.visible = false;
      highlight.visible = true;
      this.highlightedImageId = highlight.userData.id;
      this.viewer.setCameraToPanel(highlight.children);
    }
  }


  rightLinkedMedia = null;
  rightImage;
  getDXFRightLinkedImages(modelId: string) {
    this.rightLinkedMedia = null;
    this.labelSubscription = this.backendService
      .getDXFLinkedImages(this.assetId, modelId, this.rightProjectId).subscribe((linkImages: any) => {
        if (linkImages && linkImages.linkFile) {
          this.backendService.getFile(linkImages.linkFile).subscribe((medias: any) => {
            this.rightLinkedMedia = JSON.parse(medias) || [];
            if (this.panelsVisible && this.rightImageId) {
              const observable = interval(500);
              this.rightImageSubscription = observable.pipe(
                filter(() => {
                  return this.nodes.length && this.viewer && this.viewer.scene.children.filter(o => o.name === 'PanelViewer' || o.name === 'DXFViewer').length == 2;
                })
              ).subscribe(() => {
                this.rightImageSubscription.unsubscribe();
                this.backendService.getImage$<any>(this.rightImageId).subscribe((image: any) => {
                  if (image.deleted != true) {
                    this.rightImage = image;
                  }
                });
                this.markedLabels(this.rightImageId, this.rightLinkedMedia, 0X0000FF, 'right')

              });
            }
          })
        }
      }, (error) => {
        throw error;
      });
  }

  openModal(data, node) {
    const project = this.projects.find(o => o.id == this.projectId);
    if (!project) {
      this.toaster.warning('You are not able to access your baseline project.')
      return;
    }
    if (data.type != "videos") {
      this.dialog.open(ImageModalComponent, {
        //  panelClass: 'no-border-radius-dialog',
        height: '97%',
        width: '97%',
        data: {
          ...data,
          dialogRef: this.dialogRef
        },
      });

    } else {
      this.dialog.open(VideoModalComponent, {
        data: {
          ...data,
          dialogRef: this.dialogRef
        },
        width: '100%',
        height: '100%',
        panelClass: 'video-dialog'
      });
    }

  }

  ngOnDestroy(): void {
    this.linkImageSubscription.unsubscribe();
    if (this.labelSubscription) {
      this.labelSubscription.unsubscribe();
    }
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.modelSubscription.unsubscribe();
    if (this.viewer && this.isPanelDXF) {
      this.uiService.panelDXF = this.viewer.scene.children.find(o => o.name === 'PanelViewer');
      this.uiService.rawDXF = this.viewer.scene.children.find(o => o.name === 'DXFViewer');
      this.uiService.dxfId = this.dxfFile.id;
    }
    if (document.getElementById('dxfPanel')) {
      document.getElementById('dxfPanel').innerHTML = "";
    }
    this.viewer?.remove();
    if (this.leftImageSubscription) { this.leftImageSubscription.unsubscribe(); }
    if (this.rightImageSubscription) { this.rightImageSubscription.unsubscribe(); }
    if (this.panelSubscription) { this.panelSubscription.unsubscribe(); }
    this.cancelSelection.emit()
  }

  remove(image, node) {
    image.loading = true;
    const index = this.annotations.findIndex(o => o.id === node.id);
    this.backendService.removedLinkedImages(this.assetId, this.drawing.id, this.annotations[index].id, this.linkImages.filter(o => o.type === image.type && o.id !== image.id).map(o => o.id), image.type, this.asset.baselineProjectId).subscribe(() => {
      image.loading = false;
      const iIndex = this.linkImages[node.id].findIndex(o => o.id == image.id);
      this.linkImages[node.id].splice(iIndex, 1);
    }, error => {
      image.loading = false;
    })
  }

  removeDXFLinkImages(image, label) {
    // Change to file
    image.loading = true;
    this.backendService.removedLinkedImages(this.assetId, this.dxfFile.id, label.id, this.linkImages.filter(o => o.type === image.type && o.id !== image.id).map(o => o.id), image.type, this.projectId).subscribe(() => {
      image.loading = false;
      const iIndex = this.linkImages.findIndex(o => o.id == image.id);
      this.linkImages[label.id].splice(iIndex, 1);
    }, error => {
      image.loading = false;
    })
  }

  removeLabelPrompt(label) {
    this.deleteLabelDialogRef = this.dialog.open(this.deleteLabelDialog, {
      data: { label: label }
    });
  }

  deleteLabel(label) {
    this.backendService.removeTechnicalDrawingLabels(this.drawing.id, label).subscribe((result) => {
      this.backendService.removeLabelLinkImages(this.assetId, this.drawing.id, label.id).subscribe((result) => { })
      this.deleteLabelDialogRef.close();
    })
  }

  preview(image) {
    this.router.navigateByUrl(`/dashboard/projects/${image.projectId}/images/${image.id}`);
  }

  handleAdd() {
    if (this.technicalDrawings.length >= 20) {
      this.toaster.warning("You can max 20 technical drawings uploads");
      return;
    }
    if (this.asset.assetType === 'solar') {
      document.getElementById('tech_dxf_image').click();
    } else {
      document.getElementById('tech_image').click();

    }
  }

  fileChoose(event) {
    if (!event.target.files.length) { return };
    const file = event.target.files[0];
    const isDXFFile = (file.name.toLowerCase().indexOf("dxf") != -1) ? true : false;
    this.isImageUploading$.next(true);
    this.backendService.uploadTechnicalDrawing(file).pipe().subscribe(response => {
      this.isImageUploading$.next(false);
      this.backendService.add2DModels$(this.assetId, response.link, file.name, isDXFFile).subscribe((result: any) => {
        this.modelSubscription.unsubscribe();
        this.getModels();

      })
    }, error => {
      this.isImageUploading$.next(false);
      throw (error);
    })
  }

  toggleLeftMenu() {
    this.isLeftMenu = !this.isLeftMenu;
  }
  toggleRightMenu() {
    this.isRightMenu = !this.isRightMenu;
  }

  exportQR() {
    this.drawing.status = 'processing';
    this.backendService.exportQR(this.assetId, '2D').pipe(takeUntil(this.onDestroy$)).subscribe(response => { }, error => {
      this.drawing.status = 'failed';
      throw (error);
    })
  }

  removeDrawing(drawing) {
    if (this.technicalDrawings.find(o => o.id == drawing.id)?.labels && this.technicalDrawings.find(o => o.id == drawing.id)?.labels.length != 0) {
      this.toaster.warning("Image can not be deleted,It linked with the labels")
      return;
    }

    if (this.drawing.id == drawing.id) {
      this.drawing = null;
      this.labelSubscription.unsubscribe();
    }
    this.backendService.removeTechnicalDrawing(drawing.id)

  }

  public isChecked() {
    return this.technicalDrawings?.filter(o => o.checked).length ? false : true;
  }

  addToReport() {
    this.dialogRef.close({
      images: this.technicalDrawings?.filter(o => o.checked)
    })
  }

  toggle2DPanelView() {
    this.is2DPanelExpand = !this.is2DPanelExpand;
    if (this.baseFile && this.drawing) {
      setTimeout(() => {
        this.activate(this.drawing);
      }, 200);
    }
  }

  toggleDXFPanelView() {
    this.is2DPanelExpand = !this.is2DPanelExpand;
    this.handleResize();
  }

  filterImages() {
    if (!this.dxfFile) {
      const labels = this.annotations.filter(o => o.checked);
      this.dialogRef.close({
        modelId: this.drawing.id,
        labels: labels
      })
    } else {
      let selectedLeftProjectImages = [];
      let selectedRightProjectImages = [];

      const _this = this;
      isChecked(this.nodes);
      function isChecked(nodes) {
        nodes.forEach(node => {
          if (node.layer === 'L5' && node.checked) {
            if (_this.leftLinkedMedia && _this.leftLinkedMedia[node.id] && _this.leftLinkedMedia[node.id]['images'])
              selectedLeftProjectImages = selectedLeftProjectImages.concat(_this.leftLinkedMedia[node.id]['images'])

            if (_this.rightLinkedMedia && _this.rightLinkedMedia[node.id] && _this.rightLinkedMedia[node.id]['images'])
              selectedRightProjectImages = selectedRightProjectImages.concat(_this.rightLinkedMedia[node.id]['images'])
          }
          if (node.children && node.children.length) {
            isChecked(node.children)
          }
        });

      }
      console.log(selectedLeftProjectImages);
      console.log(selectedRightProjectImages);
      const labels = this.nodes.filter(o => o.checked);
      this.dialogRef.close({
        modelId: this.dxfFile.id,
        labels: labels,
        type: 'DXF',
        leftProjectImages: selectedLeftProjectImages.filter(this.generalService.onlyUnique),
        rightProjectImages: selectedRightProjectImages.filter(this.generalService.onlyUnique)
      })
    }
  }

  getSelectedLabels() {
    let checkboxChecked = false;
    if (!this.dxfFile) {
      if (this.annotations && this.annotations.filter(o => o.checked).length > 0) {
        return true;
      }
      return false;
    } else {
      if (this.nodes) {
        isChecked(this.nodes)
        if (checkboxChecked) {
          return true;
        }
      }
      return false;
    }

    function isChecked(nodes) {
      nodes?.forEach(node => {
        if (node.checked) {
          checkboxChecked = true;
        }
        if (node.children) {
          isChecked(node.children)
        }
      });

    }

  }

  /**
* Sets up the view manager.
* @return {Viewer}
*/
  viewer;
  createViewer(model) {
    const viewerEl: any = document.getElementById('dxfPanel');
    let modelWidth = (window.innerWidth / 2);
    let modelHeight = window.innerHeight - (this.type === 'asset' ? 190 : 50);
    viewerEl.width = modelWidth;
    viewerEl.height = modelHeight;
    this.viewer = new DXFViewer(viewerEl, { modelOptions: model.modelOptions || null });
    return this.viewer;

  }


  geoReference = []
  savePoints(data) {
    this.geoReference.push({
      panel: data.panel,
      latitude: data.latitude,
      longitude: data.longitude
    })
    this.georeferenceDialogRef.close();
  }

  dxfLoading: boolean = false;
  isLoging: boolean = false;
  initDXF(model) {
    this.viewer = this.viewer || this.createViewer(model);
    this.dxfLoading = true;
    if (this.uiService.dxfId === this.dxfFile.id && this.uiService.rawDXF) {
      this.viewer.scene.add(this.uiService.rawDXF);
      this.viewer.centerCamera();
      this.dxfLoading = false;
    } else {
      this.backendService.getFile(model.fileUrl).subscribe((result: any) => {
        this.viewer.loadDXF(result);
        this.dxfLoading = false;
        if (!this.dxfFile.panelLayers) {
          const observable = interval(1000);
          this.panelSubscription = observable.pipe(
            filter(() => {
              return this.viewer.layers && this.dxfFile
            })
          ).subscribe(() => {
            this.panelSubscription.unsubscribe();
            if (this.viewer.layers) {
              const dialogRef = this.dialog.open(DxfLayersComponent, {
                disableClose: true,
                data: { lists: Object.keys(this.viewer?.layers) },
              });
              dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                  this.backendService.update2DModels$(this.dxfFile.id, { panelLayers: result }).subscribe(() => {
                    this.backendService.dxfExtract(this.dxfFile.id).subscribe(() => { });
                  });
                }
              });
            }
          });
        }
      })
    }

    /**Click event */
    const _this = this;
    let click: any;
    this.viewer.renderer.domElement.addEventListener('pointerdown', onDownClick, true)
    function onDownClick(event) {
      if (_this.viewer.toolBox) {
        return;
      }
      const time = new Date().getTime();
      const currentTime = click;
      click = time;
      if (time - currentTime < 300 && event.which === 1) {
        if (_this.type != 'asset' && _this.type != 'project') {
          return;
        }

        //double click event

        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera(
          {
            x: ((event.offsetX) / _this.viewer.renderer.domElement.clientWidth) * 2 - 1,
            y: -((event.offsetY) / _this.viewer.renderer.domElement.clientHeight) * 2 + 1,

          },
          _this.viewer.camera
        )
        const intersects = raycaster.intersectObjects(_this.viewer.mesh.children, false)
        if (intersects.length && intersects[0].object.userData.entity) {

          function getNodeByLayer(data, handle) {
            const panels = [];
            function search(tree) {
              if (tree.panels.includes(handle)) {
                panels.push(tree);
              }

              if (tree.children) {
                tree.children.forEach(child => search(child));
              }
            }

            data.forEach(item => search(item));
            return panels;
          }
          const nodes = getNodeByLayer(_this.nodes, intersects[0].object.userData.entity.handle);
          if (nodes.length) {
            _this.treeComponent.treeModel.collapseAll();
          }
          nodes.forEach(node => {
            const panel = _this.treeComponent.treeModel.getNodeById(node.id);
            if (panel) {
              panel.expand(true);
              if (node.layer === 'L5') {
                _this.handleNodeClick(_this.treeComponent.treeModel.getNodeById(node.id), false)
              }
            }

          });

        }
        /*  if (intersects.length && this.enableGepPoints) {

            intersects.forEach(intersect => {
              intersect.object.material.color.set(0xff9800);
            });
          }*/

      }

    };

    /**Selecton event */
    this.viewer.selectionChanges.subscribe((data) => {
      let panelData: any = {};
      if (this.isLoging || !data || !data.panels.length || this.viewer?.toolBox === 'L5') {
        return;
      }
      this.isLoging = true;

      if (this.viewer.toolBox === 'georeference') {
        this.georeferenceDialogRef = this.dialog.open(this.georeferenceDialog, {
          data: panelData
        });
        return;
      }
      if (this.viewer.toolBox === 'L1') {
        panelData = {
          ...data,
          structure: [],
          header: this.levels['L1'],
          name: this.nodes.length ?
            this.nodes[0]?.name.replace(/\d/g, '') + `${this.nodes.length + 1}` : `${this.levels['L1']} 1`
        }
      }

      if (this.viewer.toolBox === 'L2') {
        if (!this.nodes || !this.nodes.length) {
          this.toaster.warning(DXF.No_PDT);
          this.isLoging = false;
          return;
        }
        const maxMatchedArray = this.maxMatchedRecords(this.nodes, data.panels);
        if (maxMatchedArray.length == 0) {
          this.toaster.warning(DXF.PDT_WRONG_MARKED);
          this.isLoging = false;
          return;
        }

        const index = this.nodes.findIndex(o => o.id === maxMatchedArray[0].id)
        panelData = {
          ...data,
          indexes: [index],
          header: this.levels['L2'],
          structure: [maxMatchedArray[0].name],
          name: this.nodes[index][`${props}`].length ?
            this.nodes[index][`${props}`][0]?.name.replace(/\d/g, '') + `${this.nodes[index][`${props}`].length + 1}` : `${this.levels['L2']} 1`
        }

      }

      if (this.viewer.toolBox === 'L3') {
        if (!this.nodes || !this.nodes.length) {
          this.toaster.warning(DXF.No_PDT);
          this.isLoging = false;
          return;
        }
        const maxMatchedArray = this.maxMatchedRecords(this.nodes, data.panels);
        if (maxMatchedArray.length == 0) {
          this.toaster.warning(DXF.PDT_WRONG_MARKED);
          this.isLoging = false;
          return;
        }
        const index = this.nodes.findIndex(o => o.id === maxMatchedArray[0].id);
        const maxMatchedArrayJB = this.maxMatchedRecords(this.nodes[index][`${props}`], data.panels);
        if (maxMatchedArrayJB.length == 0) {
          this.toaster.warning(DXF.JB_WRONG_MARKED);
          this.isLoging = false;
          return;
        }
        const JBIndex = this.nodes[index][`${props}`].findIndex(o => o.id === maxMatchedArrayJB[0].id);
        panelData = {
          ...data,
          indexes: [index, JBIndex],
          header: this.levels['L3'],
          structure: [maxMatchedArray[0].name, maxMatchedArrayJB[0].name],
          name: this.nodes[index][`${props}`][JBIndex][`${props}`].length ?
            this.nodes[index][`${props}`][JBIndex][`${props}`][0]?.name.replace(/\d/g, '') + `${this.nodes[index][`${props}`][JBIndex][`${props}`].length + 1}` : `${this.levels['L3']} 1`
        }
      }

      if (this.viewer.toolBox === 'L4') {
        if (!this.nodes || !this.nodes.length) {
          this.toaster.warning(DXF.No_PDT);
          this.isLoging = false;
          return;
        }
        const maxMatchedArray = this.maxMatchedRecords(this.nodes, data.panels);
        if (maxMatchedArray.length == 0) {
          this.toaster.warning(DXF.PDT_WRONG_MARKED);
          this.isLoging = false;
          return;
        }
        const index = this.nodes.findIndex(o => o.id === maxMatchedArray[0].id);

        const maxMatchedArrayJB = this.maxMatchedRecords(this.nodes[index][`${props}`], data.panels);
        if (maxMatchedArrayJB.length == 0) {
          this.toaster.warning(DXF.JB_WRONG_MARKED);
          this.isLoging = false;
          return;
        }
        const JBIndex = this.nodes[index][`${props}`].findIndex(o => o.id === maxMatchedArrayJB[0].id);

        const maxMatchedArrayTR = this.maxMatchedRecords(this.nodes[index][`${props}`][JBIndex][`${props}`], data.panels);
        if (maxMatchedArrayTR.length == 0) {
          this.toaster.warning(DXF.TR_WRONG_MARKED);
          this.isLoging = false;
          return;
        }

        const TRIndex = this.nodes[index][`${props}`][JBIndex][`${props}`].findIndex(o => o.id === maxMatchedArrayTR[0].id);
        panelData = {
          ...data,
          indexes: [index, JBIndex, TRIndex],
          header: this.levels['L4'],
          structure: [maxMatchedArray[0].name, maxMatchedArrayJB[0].name, maxMatchedArrayTR[0].name],
          name: this.nodes[index][`${props}`][JBIndex][`${props}`][TRIndex][`${props}`].length ?
            this.nodes[index][`${props}`][JBIndex][`${props}`][TRIndex][`${props}`][0]?.name.replace(/\d/g, '') + `${this.nodes[index][`${props}`][JBIndex][`${props}`][TRIndex][`${props}`].length + 1}` : `${this.levels['L4']} 1`
        }
      }

      if (this.viewer.toolBox === 'L5') {
        if (!this.nodes || !this.nodes.length) {
          this.toaster.warning(DXF.No_PDT);
          this.isLoging = false;
          return;
        }
        const maxMatchedArray = this.maxMatchedRecords(this.nodes, data.panels);
        if (maxMatchedArray.length == 0) {
          this.toaster.warning(DXF.PDT_WRONG_MARKED);
          this.isLoging = false;
          return;
        }
        const index = this.nodes.findIndex(o => o.id === maxMatchedArray[0].id);

        const maxMatchedArrayJB = this.maxMatchedRecords(this.nodes[index][`${props}`], data.panels);
        if (maxMatchedArrayJB.length == 0) {
          this.toaster.warning(DXF.JB_WRONG_MARKED);
          this.isLoging = false;
          return;
        }
        const JBIndex = this.nodes[index][`${props}`].findIndex(o => o.id === maxMatchedArrayJB[0].id);

        const maxMatchedArrayTR = this.maxMatchedRecords(this.nodes[index][`${props}`][JBIndex][`${props}`], data.panels);
        if (maxMatchedArrayTR.length == 0) {
          this.toaster.warning(DXF.TR_WRONG_MARKED);
          this.isLoging = false;
          return;
        }

        const TRIndex = this.nodes[index][`${props}`][JBIndex][`${props}`].findIndex(o => o.id === maxMatchedArrayTR[0].id);
        const maxMatchedArraySTR = this.maxMatchedRecords(this.nodes[index][`${props}`][JBIndex][`${props}`][TRIndex][`${props}`], data.panels);
        if (maxMatchedArraySTR.length == 0) {
          this.toaster.warning(DXF.STR_WRONG_MARKED);
          this.isLoging = false;
          return;
        }

        const STRIndex = this.nodes[index][`${props}`][JBIndex][`${props}`][TRIndex][`${props}`].findIndex(o => o.id === maxMatchedArraySTR[0].id);
        panelData = {
          ...data,
          indexes: [index, JBIndex, TRIndex, STRIndex],
          header: this.levels['L5'],
          structure: [maxMatchedArray[0].name, maxMatchedArrayJB[0].name, maxMatchedArrayTR[0].name, maxMatchedArraySTR[0].name],
          name: this.nodes[index][`${props}`][JBIndex][`${props}`][TRIndex][`${props}`][STRIndex][`${props}`].length ?
            this.nodes[index][`${props}`][JBIndex][`${props}`][TRIndex][`${props}`][STRIndex][`${props}`][0]?.name.replace(/\d/g, '') + `${this.nodes[index][`${props}`][JBIndex][`${props}`][TRIndex][`${props}`][STRIndex][`${props}`].length + 1}` : `${this.levels['L5']} 1`
        }
      }

      if (this.viewer.toolBox === 'L1') {
        this.createShape(panelData);
      } else {
        if (this.dxfDialogRef) {
          this.dxfDialogRef.close();
        }
        panelData.structure.push(panelData.name)
        this.dxfDialogRef = this.dialog.open(this.dxfDialog, {
          data: panelData
        });
        this.dxfDialogRef.afterClosed().subscribe(() => {
          this.isLoging = false;
        })
      }

      //  this.createShape(panelData);

    });


  }

  toolBoxValue: string;
  onValChange(event) {
    if (this.viewer && this.toolBoxValue === this.viewer.toolBox) {
      this.viewer.toolBox = "";
      this.toolBoxValue = "";
    }
    else {
      this.toolBoxValue = event;
    }
  }


  createShape(data) {
    let parent: any;
    if (this.viewer.toolBox === 'L1') {
      if (!this.nodes) {
        this.nodes = [];
      }
      parent = this.nodes;
    }
    if (this.viewer.toolBox === 'L2') {
      if (!this.nodes[data.indexes[0]][`${props}`]) {
        this.nodes[data.indexes[0]][`${props}`] = [];
      }
      parent = this.nodes[data.indexes[0]][`${props}`];
    }

    if (this.viewer.toolBox === 'L3') {
      if (!this.nodes[data.indexes[0]][`${props}`]
      [data.indexes[1]][`${props}`]
      ) {
        this.nodes[data.indexes[0]][`${props}`][data.indexes[1]][`${props}`] = [];
      }
      parent = this.nodes[data.indexes[0]][`${props}`][data.indexes[1]][`${props}`]
    }

    if (this.viewer.toolBox === 'L4') {
      if (!this.nodes[data.indexes[0]][`${props}`]
      [data.indexes[1]][`${props}`][data.indexes[2]][`${props}`]
      ) {
        this.nodes[data.indexes[0]][`${props}`][data.indexes[1]][`${props}`][data.indexes[2]][`${props}`] = [];
      }
      parent = this.nodes[data.indexes[0]][`${props}`][data.indexes[1]][`${props}`][data.indexes[2]][`${props}`]

    }

    if (this.viewer.toolBox === 'L5') {
      if (!this.nodes[data.indexes[0]][`${props}`]
      [data.indexes[1]][`${props}`][data.indexes[2]][`${props}`][data.indexes[3]][`${props}`]
      ) {
        this.nodes[data.indexes[0]][`${props}`][data.indexes[1]][`${props}`][data.indexes[2]][`${props}`][data.indexes[3]][`${props}`] = [];
      }
      parent = this.nodes[data.indexes[0]][`${props}`][data.indexes[1]][`${props}`][data.indexes[2]][`${props}`][data.indexes[3]][`${props}`]
    }
    const children = [];
    if (this.viewer.toolBox === 'L4') {
      data.panels.map(o => o.entity.handle).forEach((handle, i) => {
        children.push({
          name: `Pnl ${i + 1}`,
          panels: [handle],
          //  startPoint: data.startPoint,
          //  endPoint: data.endPoint,
          id: uuidv4(),
          layer: `L5`
        })
      });
    }

    parent.push({
      name: data.name,
      children: children,
      panels: data.panels.map(o => o.entity.handle),
      startPoint: data.startPoint,
      endPoint: data.endPoint,
      id: data.id,
      layer: data.layer,
      position: {
        points: this.viewer.camera.position.copy(this.viewer.camera.position.clone()).toArray(),
        controls: this.viewer.controls.target.toArray(),
        zoom: this.viewer.camera.zoom,
        left: this.viewer.camera.left,
        right: this.viewer.camera.right,
        bottom: this.viewer.camera.bottom,
        top: this.viewer.camera.top,

      },

      rotation: {
        x: this.viewer.camera.rotation.x,
        y: this.viewer.camera.rotation.y,
        z: this.viewer.camera.rotation.z,

      },

    })
    const nodes = [];
    this.nodes.forEach((e1, p) => {
      const node = {
        id: e1.id,
        name: e1.name,
        ...e1,
        children: [],
      };
      nodes.push(node)
      e1[`${props}`]?.forEach((e2, i) => {
        const node = {
          id: e2.id,
          name: e2.name,
          ...e2,
          children: []
        }
        nodes[p].children.push(node);
        e2[`${props}`]?.forEach((e3, j) => {
          const node = {
            id: e3.id,
            name: e3.name,
            ...e3,
            children: [],
          };
          nodes[p].children[i].children.push(node);
          e3[`${props}`]?.forEach((e4, k) => {
            const node = {
              id: e4.id,
              name: e4.name,
              ...e4,
              children: [],
            };
            nodes[p].children[i].children[j].children.push(node);
            e4[`${props}`]?.forEach((e5, l) => {
              const node = {
                id: e5.id,
                name: e5.name,
                ...e5,
                children: [],
              };
              nodes[p].children[i].children[j].children[k].children.push(node);
            });
          });
        });
      });
    });

    this.nodes = nodes;
    if (this.dxfDialogRef) {
      this.dxfDialogRef.close();
    }
    this.isLoging = false;
    this.viewer.createBox(data.id, data.startPoint, data.endPoint, data.layer);
    this.triggerUpdate();

  }

  maxMatchedRecords(array = [], panels) {

    const item = array.reduce((maxMatchRecord, item) => {
      const matchCount = panels.reduce((count, filterItem) => {
        return count + (item.panels.includes(filterItem.entity.handle) ? 1 : 0);
      }, 0);

      if (matchCount > (maxMatchRecord ? maxMatchRecord.maxMatchCount : 0)) {
        return { maxMatchCount: matchCount, record: item };
      } else {
        return maxMatchRecord;
      }
    }, null);
    if (item && item.maxMatchCount) {
      return [item.record];
    } else {
      return [];
    }
    /* 
    const filteredArray = array.filter(item => panels.some((filterItem) => {
        return item.panels.includes(filterItem.entity.handle);
      }))
    
    let maxMatchedArray = [];
      let maxMatchedCount = 0;
      filteredArray.forEach(item => {
        const matchedCount = item.panels.filter(panel => panels.some(filterItem => filterItem.entity.handle === panel)).length;
        if (matchedCount > maxMatchedCount) {
          maxMatchedArray = [item];
          maxMatchedCount = matchedCount;
        } else if (matchedCount === maxMatchedCount) {
          maxMatchedArray.push(item);
        }
      });
      return maxMatchedArray;
      */
  }

  selectedPanelId: string;
  handleNodeClick(node, cameraFitToObject: boolean = false) {
    let panels = node.data.panels || [];
    this.selectedPanelId = node.data.id;
    if (node.data.layer === 'L5' && this.linkedMedia && (this.type == 'asset' || this.type == 'project')) {
      this.selectedLabel = node.data;
      let keys = Object.keys(this.linkedMedia);
      this.linkImages = [];
      this.backendService.cancelFetchImage$.next();
      this.backendService.getPanels(this.projectId, panels[0]).subscribe((panel: any) => {
        const element = keys.find(o => o === this.selectedLabel.id);
        if (element) {
          this.linkedMedia[element]['images']?.forEach(imageId => {
            if (imageId && !this.linkImages.find(o => o.id == imageId)) {
              this.backendService.getImage$<any>(imageId).subscribe((image: any) => {
                if (image.deleted != true) {
                  this.linkImages.push({ ...image,uuid:uuidv4(), type: 'images',panel })
                }
              });
            }
          })
          this.linkedMedia[element]['videos']?.forEach(videoId => {
            if (videoId && !this.linkImages.find(o => o.id == videoId)) {
              this.backendService.getVideo$<any>(videoId).subscribe((video: any) => {
                if (video.deleted != true) {
                  this.linkImages.push({ ...video, type: 'videos' })
                }
              });
            }
          })
        }
      })

    }

    if (node.data.layer != `L5`) {
      // hide all marked boundaries and marked selected one
      const groups = this.viewer.scene.children.filter(o => o.name != 'PanelViewer' && o.name != 'DXFViewer' &&
        o.name != node.data.id);
      groups.forEach(group => {
        group.visible = false;
      });
      const shape = this.viewer.scene.children.find(o => o.name === node.data.id);
      if (!shape) {
        this.viewer.createBox(node.data.id, node.data.startPoint, node.data.endPoint, node.data.layer)
      } else {
        shape.visible = true;
      }
    }

    if (node.data.layer === `L5`) {
      const groups = this.viewer.scene.children.filter(o => o.name === 'shapeLayers' && o.userData.id != node.data.id);
      groups.forEach(group => {
        group.visible = false
      });
      const scene = this.viewer.scene.children.filter(o => o.userData?.id === node.data.id);
      if (scene.length === 0) {
        const panelLayer = this.viewer.scene.children.filter(o => o.name === 'PanelViewer');
        if (panelLayer.length) {
          const layers = panelLayer[0].children.filter(layer => panels.includes(layer.userData.entity.handle));
          this.viewer.createGroup(node.data.id, layers.map(o => o.userData.entity), 0xff0000, cameraFitToObject)
        }
      } else {
        scene[0].visible = true;
      }

    } else {
      /* if (node.data.position) {
         this.handleResize();
         this.viewer.camera.position.fromArray(node.data.position.points)
         this.viewer.controls.target.fromArray(node.data.position.controls)
         this.viewer.camera.zoom = node.data.position.zoom;
         this.viewer.camera.updateProjectionMatrix();
       }*/
    }

    this.handleResize();
    const selectedShape = this.viewer.scene.children.filter(o => o.name === 'PanelViewer')[0].children.filter(o => node.data.panels.includes(o.userData.entity.handle))
    this.viewer?.setCameraToPanel(selectedShape);
    //this.viewer.zoomToObject(obj, this.viewer.camera, this.viewer.renderer)
    //hide all group
  }


  handleEditNodeClick(node) {
    const name = node.data.name;
    this.renameDialogRef = this.dialog.open(this.renameDialog, {
      data: { node: node }
    })
    this.renameDialogRef.afterClosed().subscribe(() => {
      if (name != node.data.name && node.data.name) {
        this.nodes = this.replaceName(this.nodes, node.data.layer, node.data.name.replace(/\d/g, ''))
        this.triggerUpdate();
      }
    })
  }

  replaceName(nodes, layer, value) {
    nodes.forEach((node, i) => {
      if (node.layer === layer) {
        node.name = value + " " + (i + 1);
      }
      if (node.children.length) {
        this.replaceName(node.children, layer, value)
      }
    });
    return nodes;
  }

  handleDeleteNodeClick(node) {
    this.deleteLabelDialogRef = this.dialog.open(this.deleteLabelDialog, {
      data: { node: node, isDXF: true }
    });
  }

  deletedNodes = []
  deleteNodeLabel(node) {
    function removeElementById(data, targetId) {
      let deletedIds = [];
      function removeRecursive(children) {
        const filteredEntity = children.reduce((result, item) => {
          if (item.id === targetId) {
            // Add the IDs of the deleted children to the array
            deletedIds = deletedIds.concat(getChildIds(item));
            return result; // Skip this item and its children
          }

          const newItem = { ...item }; // Create a shallow copy to avoid modifying the original data

          if (item.children && item.children.length > 0) {
            // Recursively remove elements from the 'children' array
            newItem.children = removeRecursive(item.children);
          }

          result.push(newItem);
          return result;
        }, []);

        return filteredEntity;
      }

      const filteredData = data.reduce((result, item) => {
        if (item.id === targetId) {
          // Add the IDs of the deleted children to the array
          deletedIds = deletedIds.concat(getChildIds(item));
          return result; // Skip this item and its children
        }

        const newItem = { ...item }; // Create a shallow copy to avoid modifying the original data

        if (item.children && item.children.length > 0) {
          // Recursively remove elements from the 'children' array
          newItem.children = removeRecursive(item.children);
        }

        result.push(newItem);
        return result;
      }, []);

      return { filteredData, deletedIds };
    }

    // Helper function to get all child IDs
    function getChildIds(item) {
      let ids = [item.id];

      if (item.children && item.children.length > 0) {
        for (const childItem of item.children) {
          const childIds = getChildIds(childItem);
          ids = ids.concat(childIds);
        }
      }
      return ids;
    }

    const targetId = node.data.id; // The ID you want to remove
    const { deletedIds, filteredData } = removeElementById(this.nodes, targetId);
    this.nodes = filteredData;
    this.triggerUpdate();

    this.deletedNodes = this.deletedNodes.concat(deletedIds);
    deletedIds.forEach(id => {
      const panel = this.viewer.scene.children.find(o => o.name === id);
      if (panel) {
        this.viewer.scene.remove(panel)
      }
    });
    this.deleteLabelDialogRef.close();
  }

  handleResize = () => {
    if (this.viewer.scene.children) {
      let modelWidth = (window.innerWidth / (!this.is2DPanelExpand ? 2 : 1));
      let modelHeight = window.innerHeight - (this.type === 'asset' ? 190 : 50);
      this.viewer.resize(modelHeight, modelWidth)
    }
  }

  closePanel() {
    this.backendService.closeManualGeoPosition(this.dxfFile.id);
    this.geoPointsDialogRef.close();
  }

  enableGepPoints: boolean = false;
  openGeoPanelHint() {
    this.enableGepPoints = true;
    this.viewer.toolBox = "georeference";
    this.geoPointsDialogRef.close();

  }

  cancelLabels() {
    this.labelsChanged = false;
    this.nodes = JSON.parse(this.previousNode);
  }

  saveLabels() {
    const data = this.nodes;
    this.createBtnOptions.active = true;
    this.backendService.uploadLabelsJson(this.dxfFile.id, data).pipe().subscribe((response: any) => {
      this.backendService.update2Dlabels$(this.dxfFile.id, response.link, this.levels).subscribe()
      this.labelsChanged = false;
      this.createBtnOptions.active = false;
      this.toaster.success("DXF drawing and labels saved successfully.");
    }, error => {
      this.toaster.error("Something issue to store your labels");
      this.createBtnOptions.active = false;
      throw (error);

    })

    /*  this.backendService.createTechnicalDrawingLabels(this.dxfFile, data, true).subscribe((result) => {
     if (this.deletedNodes.length) {
       this.backendService.removedDXFLabelLinkImages(this.assetId, this.dxfFile.id, this.deletedNodes).subscribe((result) => { })
       this.deletedNodes = [];
     }
      })
      */
  }

  previousNode: any;
  getDXFLabels(dxf) {
    if (dxf.labelsFile) {
      this.backendService.getFile(dxf.labelsFile).subscribe((labels: any) => {
        this.previousNode = labels;
        this.nodes = JSON.parse(labels) || [];
      })
    }

  }

  captureNew() {
    const renderer = this.viewer.renderer;
    this.isImageUploading$.next(true);
    const imgData = renderer.domElement.toDataURL('image/jpeg');
    this.backendService.uploadBase3DScreenshots(imgData).pipe().subscribe((response: any) => {
      this.isImageUploading$.next(false);
      this.backendService.add2DScreenshots$(this.dxfFile.id, response.link).subscribe()
    }, error => {
      this.isImageUploading$.next(false);
      throw (error);
    })
  }

  addDXFToReport() {
    this.dialogRef.close({
      images: this.dxfFile?.screens?.filter(o => o.checked),
      modelId: this.dxfFile.id,
      annotations: this.annotations
    })
  }

  edit(screen) {
    screen.editing = true;
  }

  update() {
    const screens = this.dxfFile.screens.filter(function (obj) {
      delete obj.editing;
      return delete obj.checked;
    });
    this.backendService.update2DScreenshots$(this.dxfFile.id, screens).subscribe()
  }

  public isDXFFileChecked() {
    return this.dxfFile?.screens?.filter(o => o.checked).length ? false : true;
  }

  deleteScreen(index) {
    this.dxfFile.screens.splice(index, 1);
    this.update();
  }

  deletePrompt() {
    if (this.nodes.length) {
      this.toaster.warning('Delete all labels before deleting the model')
      return;
    }
    this.confirmDialog
      .open(
        `Are you sure you want to delete the model?`,
        {
          trueButtonTitle: 'Yes',
          falseButtonTitle: 'No'
        }
      )
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.ngOnDestroy();
          this.uiService.panelDXF = null;
          this.uiService.rawDXF = null;
          this.uiService.dxfId = null;
          this.backendService.deleteDXFModel(this.dxfFile.id).then((result: any) => {
            this.baseFile = false;
            this.isPanelDXF = false;
            this.dxfFile = null;
            this.viewer = null;
            this.selectedLabel = null
            this.getModels();
          });
        }
      });
  }

  nodeSelect(node, event) {
    if (event) {
      isChecked(node.data);
    }
    function isChecked(node) {
      node.children.forEach(child => {
        child.checked = true;
        if (child.children) {
          isChecked(child)
        }
      });
    }
  }

  calculateRotation(image): number {
    if (image.metaData && !image.isRotate) {
      // You may need to adjust this logic based on your specific requirements
      return -parseFloat(image.metaData.GimbalYawDegree);//
    }
    return 0;
  }

  calculateImageRotation(image): number {
    if (image.isRotate) {
      return parseFloat(image.metaData.GimbalYawDegree);
    }
    return 0;
  }

  checkYaw(image) {
    return image.metaData?.GimbalYawDegree && parseFloat(image.metaData.GimbalYawDegree) ? true : false
  }

  rotateImage(image) {
    if (!image.isRotate) {
      image.isRotate = false
    }
    image.isRotate = !image.isRotate;
  }

  onDoubleTap(level) {
    this.levelDialogRef = this.dialog.open(this.levelDialog, {
      data: {
        name: this.levels[level],
        level: level,
      },
    });
  }

  saveLevel(data) {
    if (data.name != this.levels[data.level]) {
      this.nodes = this.replaceName(this.nodes, this.levels[data.level], data.name.replace(/\d/g, ''))
      this.triggerUpdate();
    }
    this.levels[data.level] = data.name;
    this.levelDialogRef.close();
  }

  triggerUpdate() {
    this.selectionChange.emit({
      nodes: this.nodes,
      levels: this.levels,
      id: this.dxfFile.id
    })
  }
}