import { UiService } from './../services/ui.service';
import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, interval, observable, Observable, of, Subject, Subscription } from 'rxjs';
import { prop } from 'ramda';
import { Router, ActivatedRoute } from '@angular/router';
import { BackendService } from '../services/backend.service';
import { Project } from '../projects/item/project.type';
import { ProjectAddFolderComponent } from './add-folder/add-folder.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { TaggedArea } from '../models/tagging.interface';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ProjectAddGroupComponent } from './add-group/add-group.component';
import { ImageTitleImageData, ImageTitleImageDialogComponent } from '../image-title-image-dialog/image-title-image-dialog.component';
import { debounceTime, distinctUntilChanged, map, share, take, takeUntil, tap } from 'rxjs/operators';
import { GeneralService } from '../services/general.service';
import { ProjectRenameFolderComponent } from './rename-folder/rename-folder.component';
import { ITreeOptions, ITreeState, TreeComponent, TreeModel, TreeNode, TREE_ACTIONS } from '@circlon/angular-tree-component';
import { IImage } from '../models/image.interface';
import { ToastrService } from 'ngx-toastr';
import { Status } from '../models/app.enum';


@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements OnInit, OnDestroy {

  public folderContents$: Observable<any[]>;
  public videoContents$: Observable<any[]>;
  public memberSubscription = new Subscription();
  public folderSubscription = new Subscription();
  public groupsSubscription = new Subscription();
  public unprocessedImagesSubscription = new Subscription();
  public exclude3DImagesSubscription = new Subscription();
  public folderContentsSubscription = new Subscription();
  public videoContentsSubscription = new Subscription();
  public projectSubscription = new Subscription();
  public tagSubscription = new Subscription();
  public aiEventSubscription = new Subscription();
  public labelSubscription = new Subscription();

  public folders = [];
  public tags = [];
  public members = [];
  public roles = [];
  public projectId: string;
  // public project: Project;
  public allNodesExpanded = false;
  public projects$: Observable<Project[]>;
  private _activeFolder: any;
  public activeFolderPath: string;
  public timerId: any;
  public activeFolderTimerId: any;
  public contextMenuPosition = { x: '0px', y: '0px' };
  private selectedGroupImages = [];
  private tagsSubscription = new Subscription();
  selectedIndex: number = 0;
  private startIndex = 0;
  private endIndex = 32;
  public changeSubscription: Subscription = new Subscription();
  public projectImages = [];

  public isPanelShow = true;
  public models = [];
  @ViewChild('treeComponent') private treeComponent: TreeComponent;
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  @ViewChild('tree') set tree(value) {
    this.uiService.tree = value;
  }

  groups = [];
  unProcessedImages = [];
  getLevel = prop('level');

  onDestroy$ = new Subject();

  public set images(images) {
    this.uiService.images = images;
  }

  public get images(): any[] {
    return this.uiService.images;
  }

  public set videos(videos) {
    this.uiService.videos = videos;
  }

  public get videos(): any[] {
    return this.uiService.videos;
  }
  public set checkedImages(images) {
    this.uiService.checkedImages = images;
  }

  public get checkedImages(): any[] {
    return this.uiService.checkedImages;
  }

  public set filteredImages(images) {
    this.uiService.filteredImages = images.filter(o => o.projectId == this.uiService.selectedProjectId)
  }

  public get filteredImages(): any[] {
    return this.uiService.filteredImages;
  }

  public set selectedTag(value) {
    this.uiService.selectedTag = value;
  }

  public get selectedTag(): string {
    return this.uiService.selectedTag;
  }

  public set verifiedTag(value) {
    this.uiService.verifiedTag = value;
  }

  public get verifiedTag(): string {
    return this.uiService.verifiedTag;
  }

  public set selectedTab(value) {
    this.uiService.selectedTab = value;
  }

  public get selectedTab(): string {
    return this.uiService.selectedTab;
  }

  public set selectedGroup(value) {
    this.uiService.selectedGroup = value;
  }

  public get selectedGroup(): string {
    return this.uiService.selectedGroup;
  }

  public get nodes(): any {
    return this.uiService.nodes;
  }

  public set nodes(value) {
    this.uiService.nodes = value;
  }

  public get movedFolder(): any {
    return this.uiService.movedFolder
  }

  public set movedFolder(value) {
    this.uiService.movedFolder = value;
  }

  get selected(): string {
    return this.uiService.selectedProjectId;
  }

  set selected(value: string) {
    this.uiService.selectedProjectId = value;
  }

  public get success() {
    return Status.SUCCESS;
  }
  public get failed() {
    return Status.FAILED;
  }
  public get process() {
    return Status.PROCESSING;
  }
  public get queued() {
    return Status.QUEUED;
  }

  set activeFolder(value) {
    if (!value) {
      this.uiService.activeFolder = null;
      return;
    }
    if (this.uiService.activeFolder && this.uiService.activeFolder.data.id == value.data.id) {
      return;
    }
    this._activeFolder = value;
    this.uiService.activeFolder = value;
    if (value) {
      this.activeFolderPath = value.data.path;
    }
    this.uiService.activeFolderPath = this.activeFolderPath;
    console.log('set', value, this.activeFolderPath);
    if (value) {
      this.uiService.isFolderContentLoading$.next(true);
      this.folderContents$ = this.backendService.getFolderContentsAll$(
        value.data.path,
        this.projectId
      );
      this.videoContents$ = this.backendService.getVideoContentsAll$(
        value.data.path,
        this.projectId
      );

    }
    if (this._activeFolder) {
      this._activeFolder.setIsActive(true);
    }

    if (this.router.url.indexOf('images') != -1) {
      if (!this.uiService.folderParams) {
        this.uiService.folderChangeWithOldImageRoute = true;
      }
      this.uiService.activeFolderChange.next(this._activeFolder);
    }

    this.images = [];
    this.filteredImages = [];

    if (value) {
      this.getFolderImages();
      this.videoContentsSubscription.unsubscribe()
      this.videoContentsSubscription = this.videoContents$.pipe(
        takeUntil(this.onDestroy$)
      ).subscribe((videos) => {
        console.log('Total videos found in folder', videos.length)
        this.videos = videos;
      }, error => {
        console.error(error)
      });
      this.cdRef.markForCheck();
    }
  }

  getFolderImages() {
    this.folderContentsSubscription.unsubscribe()
    this.uiService.isFolderContentLoading$.next(true);
    this.folderContentsSubscription = this.folderContents$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((images) => {
      console.log('Total Images found in folder', images.length);
      if (this.uiService.project.thermalColorConversionStatus === this.queued
        || this.uiService.project.thermalColorConversionStatus === this.process) {
        this.folderContentsSubscription.unsubscribe();
      }
      if (images.length === 0 || images[0].folderPath === this.activeFolderPath) {
        if (this.uiService.currentUploadFolderPath === this.activeFolderPath) {
          this.images = [...this.uiService.uploadImages, ...images];
        } else {
          this.images = images;
        }
      }
      this.uiService.folderImages = this.images;
      if (images.length == 0) {
        this.uiService.isFolderContentLoading$.next(false);
      }
      this.getAnnotations();

    }, error => {
      console.error(error)
      this.uiService.isFolderContentLoading$.next(false);
    });
  }

  clearFilters() {
    this.verifiedTag = 'All';
    this.selectedTag = 'All';
    this.uiService.tagSelectedEvent$.next();
  }

  get activeFolder(): any {
    return this._activeFolder;
  }

  getNodeHeight(node){
  // Example: Set height based on the number of children (adjust as needed)

  // Set a default base height
  let baseHeight = 40;


  // Adjust the height based on the node's level
  const level = node.level;
  switch (level) {
    case 1:
      baseHeight += 20; // Adjust the height for level 1
      break;
    case 2:
      baseHeight += 30; // Adjust the height for level 2
      break;
    case 3:
      baseHeight += 40; // Adjust the height for level 3
      break;
    case 4:
      baseHeight += 50; // Adjust the height for level 4
      break;
    case 5:
      baseHeight += 60; // Adjust the height for level 5
      break;
    default:
      // Optionally, handle other levels
      break;
  }
  const additionalHeightPerChild = 20; // Set additional height per child
  return baseHeight + (node.data?.children?.length || 0) * additionalHeightPerChild;

}
  treeOptions: ITreeOptions = {
    allowDrag: false,
    allowDrop: false,
    actionMapping: {
      mouse: {
        drop: (tree, node, $event, { from, to }) => {
          if (from.data.children.length || from.data.id === to.parent.data.id
            || to.parent.data.children.includes(node => node.id === from.data.id)) {
            return false;
          }
          if (!to.parent.getField('children')) {
            to.parent.setField('children', []);
          }

          if (from && to.parent) {
            const newPath = to.parent.data.path || `projects/${this.projectId}`;
            let newImagePath;
            const newBreadcrumbName = to.parent.data.breadcrumb ?
              to.parent.data.breadcrumb.name + '/' + from.data.breadcrumb.name.split('/').slice(-1)[0] :
              from.data.name;
            from.data.breadcrumb.name = newBreadcrumbName;

            /*  this.backendService.createFolder(newPath, from.data.name).then((docRef) => {
                newImagePath = `${newPath}/folders/${docRef.id}`;
                return this.backendService.deleteDoc(from.data.path).pipe(tap(() => {
                  this.getFolders();
                })).subscribe();
              }).then(() => {
                return this.backendService.getFolderContents$(from.data.path, this.projectId).pipe(
                  map(images => {
                    images.map(image => image.id).forEach(id => {
                      this.backendService.changeFolderForImage(id, this.projectId, newImagePath);
                    })
                  })
                ).toPromise()
              })
              */
          }
        }
      }
    }

  };

  selectedLabelNode;
  labelId;
  labelTreeOptions: ITreeOptions = {
    allowDrag: false,
    allowDragoverStyling:false,
    allowDrop: false,
   // useVirtualScroll:true,
  //  nodeHeight: (node: TreeNode) => this.getNodeHeight(node),
    actionMapping: {
      mouse: {
        click: (tree, node) => {
          if (this.uiService.asset.baseModel == '2d') {
            if (this.uiService.asset.assetType === 'solar') {
              this.labelId = node.data.id;
              node.setIsActive(true);
              this.modelId = this.dxfModel.id;
              if (!this.linkedMedia || !Object.keys(this.linkedMedia).length) {
                this.getDXFLinkImages(this.dxfModel.id);
              }
              this.selectedLabelNode = node;
            } else {
              if (node.isLeaf) {
                this.labelId = node.data.id;
                node.setIsActive(true);          
                if (node.data.id == 'unlabelled') {
                  this.linkedMedia = [];
                  return;
                }

                if (!this.selectedLabelNode || this.selectedLabelNode.data.id != node.parent.data.id) {
                  this.modelId = node.parent.data.id;
                  this.getLinkImages(node.parent.data.id);
                }
                this.selectedLabelNode = node;
              }

            }
          } else {
            this.labelId = node.data.id;
            node.setIsActive(true);
            if (node.data.id == 'unlabelled') {
              this.linkedMedia = [];
              return;
            }
          
            if (!this.selectedLabelNode || this.selectedLabelNode.data.id != node.data.id) {
              this.getLinkImages(this.modelId);
            }
            this.selectedLabelNode = node;

          }

        }
      }
    }
  };
  state: ITreeState = {
    expandedNodeIds: {
      1: true,
      2: true
    },
    hiddenNodeIds: {},
    activeNodeIds: {}
  };
  public selectedSubscription: Subscription = new Subscription();
  public folderChange: Subscription = new Subscription();
  constructor(
    public backendService: BackendService,
    public uiService: UiService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private generalService: GeneralService,
    private toast: ToastrService
  ) {
    this.projects$ = backendService.userProjects.valueChanges({
      idField: 'id',
    });
    this.uiService.projects$ = this.projects$;
    this.matIconRegistry.addSvgIcon(
      `add_member`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/invite_member.svg'
      )
    );
    // reload images after canceling uploading
    this.uiService.uppyCancelAllEvent$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.activeFolder = this.activeFolder;
    });

    // listening to infiniteScroll from project-detail component
    this.uiService.imagesContainerScrollEvent$.asObservable().pipe(
      debounceTime(200)
    ).subscribe(() => {
      this.uiService.isFolderContentLoading$.next(true);
      this.startIndex = this.endIndex;
      this.endIndex = this.startIndex + 32;
      if (this.selectedTag === 'All') {
        this.filteredImages = this.filteredImages.concat(this.images.slice(this.startIndex, this.endIndex))
      } else if (this.selectedTag === 'Tagged') {
        this.filteredImages = this.filteredImages.concat(this.images.filter((image) => image.tags && image.tags.length).slice(this.startIndex, this.endIndex));
      }
      else if (this.selectedTag === 'Untagged') {
        this.filteredImages = this.filteredImages.concat(this.images.filter((image) => !image.tags || !image.tags.length).slice(this.startIndex, this.endIndex));
      }

      else if (this.selectedTag === 'Unprocessed') {
        this.filteredImages = this.filteredImages.concat(this.images.filter((image) => this.unProcessedImages.includes(image.id)).slice(this.startIndex, this.endIndex));
      }

      else if (this.selectedTag === 'Hotspots') {

        this.filteredImages = this.filteredImages.concat(this.images.filter((image) =>
          (image.hotspots && image.hotspots.length)
          ||
          (image.thermalPolygons && image.thermalPolygons.filter((polygon) => this.generalService.isHotspots(polygon) === true).length > 0)
        ).slice(this.startIndex, this.endIndex));
      }
      else if (this.selectedTag === 'Coldspots') {
        this.filteredImages = this.filteredImages.concat(this.images.filter((image) =>
          (image.coldspots && image.coldspots.length)
          ||
          (image.thermalPolygons && image.thermalPolygons.filter((polygon) => this.generalService.isHotspots(polygon) === false).length > 0)

        ).slice(this.startIndex, this.endIndex));
      }

      else {
        if (uiService.sensitiveTag) {
          this.filteredImages = this.filteredImages.concat(this.images.filter((image) =>
            image.tags && image.tags.find((tempTag) => tempTag.tag.toLowerCase() === this.selectedTag.toLowerCase()
              && this.uiService.sensitiveTag === tempTag.sensitive)
          ).slice(this.startIndex, this.endIndex));
        } else {
          this.filteredImages = this.filteredImages.concat(this.images.filter((image) =>
            image.tags && image.tags.find((tempTag) => tempTag.tag.toLowerCase() === this.selectedTag.toLowerCase())
          ).slice(this.startIndex, this.endIndex));
        }
      }
      this.uiService.isFolderContentLoading$.next(false);
      /*   this.backendService.getFolderContentsNext$(this.activeFolderPath, this.projectId).pipe(
           takeUntil(this.onDestroy$)
         ).subscribe(images => {
           this.images = this.generalService.makeImageArrayUnique(this.images.concat(images));
           setTimeout(() => {
             this.isFolderContentLoading$.next(false);
           }, 1000)
   
           this.getAnnotations(images);
           
   
         }, error => {
           console.error(error)
           this.isFolderContentLoading$.next(false);
         })*/
    })

    // listening when tag component select
    this.tagsSubscription = this.uiService.tagSelectedEvent$.subscribe(() => {
      this.startIndex = 0;
      this.endIndex = 32;
      if (this.selectedTag === 'All') {
        this.filteredImages = this.images.slice(this.startIndex, this.endIndex);
      } else if (this.selectedTag === 'Tagged') {
        this.filteredImages = this.images.filter((image) => image.tags && image.tags.length).slice(this.startIndex, this.endIndex);
      } else if (this.selectedTag === 'Untagged') {
        this.filteredImages = this.images.filter((image) => !image.tags || !image.tags.length).slice(this.startIndex, this.endIndex);
      }
      else if (this.selectedTag === 'Unprocessed') {
        this.filteredImages = this.images.filter(element => this.unProcessedImages.includes(element.id)).slice(this.startIndex, this.endIndex);
      }
      else if (this.selectedTag === 'Hotspots') {
        this.filteredImages = this.images.filter((image) => (image.hotspots && image.hotspots.length)
          ||
          (image.thermalPolygons && image.thermalPolygons.filter((polygon) => this.generalService.isHotspots(polygon) === true).length > 0)

        ).slice(this.startIndex, this.endIndex);
      }
      else if (this.selectedTag === 'Coldspots') {
        this.filteredImages = this.images.filter((image) => (image.coldspots && image.coldspots.length)
          ||
          (image.thermalPolygons && image.thermalPolygons.filter((polygon) => this.generalService.isHotspots(polygon) === false).length > 0)
        ).slice(this.startIndex, this.endIndex);
      }
      else {
        if (uiService.sensitiveTag) {
          this.filteredImages = this.images.filter((image) =>
            image.tags && image.tags.find((tempTag) => tempTag.tag.toLowerCase() === this.selectedTag.toLowerCase()
              && this.uiService.sensitiveTag === tempTag.sensitive)
          ).slice(this.startIndex, this.endIndex);
        } else {
          this.filteredImages = this.images.filter((image) =>
            image.tags && image.tags.find((tempTag) => tempTag.tag.toLowerCase() === this.selectedTag.toLowerCase())
          ).slice(this.startIndex, this.endIndex);

        }
      }
      if (this.uiService.verifiedTag == 'Verified') {
        this.filteredImages = this.filteredImages.filter(o => o.verifiedAnnotations);
      }
      if (this.uiService.verifiedTag == 'Unverified') {
        this.filteredImages = this.filteredImages.filter(o => o.isAIDetection && !o.verifiedAnnotations);
      }

    })
  }

  togglePanel() {
    this.isPanelShow = !this.isPanelShow;
    this.uiService.collapsePanel$.next(this.isPanelShow);
  }

  ngOnInit(): void {
    this.selectTab({ tab: { textLabel: 'folders' } });
    this.route.params.subscribe(({ projectId }) => {
      // this.backendService.updateSummary(projectId).subscribe();
      this.uiService.selectedProjectId = this.projectId = projectId;
      this.linkedMedia = null;
      this.selectFolderTab();
      this.tagSubscription.unsubscribe();
      this.tagSubscription = this.backendService.getTags(projectId).pipe(takeUntil(this.onDestroy$)).subscribe(({ tags }) => {
        this.tags = [
          { tag: 'All' },
          { tag: 'Tagged' },
          { tag: 'Untagged' },
          { tag: 'Unprocessed' },
          ...tags,
        ];
        this.uiService.tags = this.tags;
        this.uiService.projectAITags = tags.filter(tag => tag.status == 'active' && tag.aiTag == true).sort((tag1, tag2) => tag1.tag.localeCompare(tag2.tag))
          .map(tag => {
            return {
              ...tag
            };
          })
      });
      this.getMembers();
      this.projectSubscription.unsubscribe();
      this.projectSubscription = this.backendService.getProjectById(this.projectId).pipe(takeUntil(this.onDestroy$))
        .subscribe((project) => {
          if (project) {
            this.uiService.project = project;
            if (project.thermalColorConversionStatus === this.success
              || project.thermalColorConversionStatus === this.failed) {
              this.uiService.colorPaletteChange$.next({
                fetch: true
              });
              if (this.folderContentsSubscription.closed && this.activeFolder) {
                this.getFolderImages();
              }
            }
            else if (project.thermalColorConversionStatus === this.queued
              || project.thermalColorConversionStatus === this.process) {
              this.uiService.colorPaletteChange$.next({
                fetch: false
              });
              this.folderContentsSubscription.unsubscribe();

            }
            else {
              this.uiService.colorPaletteChange$.next({
                fetch: true
              });
            }


            if (project.anomalyDetectionStatus === this.success
              || project.anomalyDetectionStatus === this.failed) {
              this.uiService.anomalyDetectionChange$.next({
                fetch: true
              });
            }
            if (project.anomalyDetectionStatus === this.queued
              || project.anomalyDetectionStatus === this.process) {
              this.uiService.anomalyDetectionChange$.next({
                fetch: false
              });
            }


            this.uiService.projectFieldsChangeEvent$.next();
            this.uiService.isMask = project.isMask || false;
            if (!this.uiService.asset) {
              this.getAsset(project.assetId);
            }

          }
        });

      if (this.uiService.asset) {
        this.getAsset(this.uiService.asset.id);
      }
      this.folderSubscription.unsubscribe();
      if (this.router.url.indexOf('images') === -1) {
        this.uiService.activeFolder = null;
        this.getFolders();
      }
      this.allNodesExpanded = false;
      this.groupsSubscription.unsubscribe();
      this.unprocessedImagesSubscription.unsubscribe();
      this.getGroups();
      this.getUnProcessedImages();
      this.exclude3DImagesSubscription.unsubscribe();
      this.get3dExcludedImages();
      this.aiEventSubscription.unsubscribe();
      this.aiEventSubscription = this.backendService.getAIEngine(this.projectId).pipe(takeUntil(this.onDestroy$)).subscribe(engines => {
        this.uiService.engine = engines;
      });
      //when image is not belongs to selected active  folder 
      this.folderChange.unsubscribe();
      this.folderChange = this.uiService.folderChange.subscribe(params => {
        this.uiService.folderParams = params;
        const splits = this.uiService.folderParams.folderPath.split("/");
        const projectId = splits[1];
        if (!this.nodes.length || projectId != this.uiService.selectedProjectId) {
          this.getFolders();
        } else {
          clearInterval(this.activeFolderTimerId);
          this.setActiveFolder()
        }
      });
    });

    this.uiService.changeProjectEvent$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      if (this.router.url.includes('projects/') && this.uiService.selectedProjectId && !this.router.url.includes(this.uiService.selectedProjectId)) {
        this.router.navigate([`/dashboard/projects/${this.uiService.selectedProjectId}`]);
      }
    });

    this.selectedSubscription = this.uiService.getProjectsEvent$.pipe(
      tap((projects: []) => {
        this.projects$ = of(projects);
        this.uiService.assetProjects = projects;
        if (projects && projects.length === 0) {
        }
        if (projects.length === 0 && this.uiService.selectedAssetNode?.parent) {
          //   this.toast.warning("Add your first proejct for this asset.");
          this.router.navigate([`/dashboard`]);
        }
      }),
    ).subscribe(() => {
      this.uiService.changeProjectEvent$.next();
      this.uiService.isAssetPanelExpand = false;
    })




    // this.uiService.imagesAnnotations$.asObservable().pipe(
    // ).subscribe((images:any) => {
    // this.images= images.filter(image=> image.folderPath === this.activeFolderPath);
    // this.filteredImages=  this.images.filter(image=> this.filteredImages.map(o=>o.id).includes(image.id))
    // if (this.selectedTag === 'All' && this.verifiedTag === 'All') {
    //   this.startIndex = 0;
    //   this.endIndex = 32;
    //   this.filteredImages = images.slice(this.startIndex, this.endIndex)
    // }
    // })

  }

  getAsset(id) {
    this.backendService.getAssetById(id).subscribe((asset) => {
      if (asset.exists) {
        this.uiService.asset = Object.assign({}, asset.data(), { id: asset.id })
        this.getLabels();
      } else {
        this.uiService.asset = null;
      }
    });
  }



  getAnnotations() {
    this.uiService.isFolderContentLoading$.next(false);

    // Create a mapping of id to projectImageContext
    const contextMapping = new Map();
    this.uiService.projectImageContexts.forEach(item => {
      contextMapping.set(item.id, item);
    });

    // Use the mapping to retrieve values for each image
    this.images = this.images.map(image => {
      const context = contextMapping.get(image.id) || {};
      return {
        ...image,
        tags: context.tags,
        thermalPolygons: context.thermalPolygons,
        coldspots: context.coldspots,
        hotspots: context.hotspots,
        verifiedAnnotations: context.verifiedAnnotations,
        isAIDetection: context.isAIDetection
      };
    });

    this.filteredImages = this.images.slice(0, this.endIndex)

    /*this.annotationSubscription.unsubscribe();
    this.annotationSubscription = combineLatest(
      images.map((image) =>
        this.backendService.getImageAnnotations$<{
          polygons: Record<string, TaggedArea>;
        }>(image.id)
      )
    ).pipe(takeUntil(this.onDestroy$)).subscribe((annotations) => {
      annotations.forEach((annotation, i) => {
        this.uiService.isFolderContentLoading$.next(false);
        images[i].tags = annotation ? (annotation as any).polygons : [];
        images[i].verifiedAnnotations = annotation ? (annotation as any).verifiedAnnotations : false;
        images[i].isAIDetection = annotation ? (annotation as any).isAIDetection : false;
        let filterInd = this.filteredImages.findIndex(o => o.id == images[i].id)
        if (filterInd != -1) {
          this.filteredImages[filterInd].tags = annotation ? (annotation as any).polygons : [];
          this.filteredImages[filterInd].verifiedAnnotations = annotation ? (annotation as any).verifiedAnnotations : false;
          this.filteredImages[filterInd].isAIDetection = annotation ? (annotation as any).isAIDetection : false;
   
        }
      }); 
        */
    /*  if (this.selectedTag === 'All' && this.verifiedTag === 'All') {
        this.startIndex = 0;
        this.endIndex = 32;
        this.filteredImages = this.images.slice(this.startIndex, this.endIndex)
      }
      */

  }

  getMembers(): any {
    this.memberSubscription.unsubscribe();
    this.memberSubscription = this.backendService
      .getProject(this.projectId)
      .subscribe((membersDoc) => {
        if (membersDoc) {
          return this.backendService
            .getUsers(Object.keys(membersDoc.people))
            .subscribe((members) => {
              this.roles = Object.keys(membersDoc.people).map((person) => {
                return {
                  id: person,
                  role: membersDoc.people[person].role,
                };
              });
              this.members = members;
              this.uiService.members = this.members;
              this.uiService.roles = this.roles;
            });
        }
      });
  }

  getFolders(): void {
    this.backendService.getFoldersLoading$.next(true);
    if (this.timerId) {
      clearInterval(this.timerId);
    }
    this.folderSubscription =
      this.backendService
        .getFolders$(this.projectId)
        .subscribe((folders) => {
          this.backendService.getFoldersLoading$.next(false);
          const result = [];
          folders.docs.forEach((doc, i) => {
            const node = {
              id: doc.id,
              name: doc.data().name,
              doc: doc.data(),
              path: doc.ref.path,
              breadcrumb: {
                name: doc.data().name,
              },
              children: [],
            };
            result.push(node);
            if (result[i]) {
              this.backendService.getFoldersOf(doc.ref, doc.ref.path, result[i]);
            }
          });
          this.nodes = result.sort((a, b) => a.name.localeCompare(b.name));
          this.uiService.nodes = this.nodes;
          this.timerId = setInterval(() => {
            this.nodes = [...this.nodes];
            this.uiService.nodes = this.nodes;
            this.uiService.folders$ = of(this.nodes);
          }, 2000);
          if (!this.uiService.folderParams) {
            this.setRootFolder()
          } else {
            clearInterval(this.activeFolderTimerId);
            this.setActiveFolder()
          }

        }, (error) => {
          this.backendService.getFoldersLoading$.next(false);
          throw error;
        });
  }

  dxfModel;
  previousNode;
  getLabels(): void {
    if (!this.uiService.asset.baseModel) {
      return
    }
    this.labelSubscription.unsubscribe();
    this.linkedMedia = [];
    if (this.uiService.asset.baseModel === '2d') {

      if (this.uiService.asset.assetType != 'solar') {
        this.labelSubscription = this.backendService
          .get2DModels$(this.uiService.project?.assetId).pipe(
            takeUntil(this.onDestroy$)
          ).subscribe((techDrawings: any) => {
            techDrawings = techDrawings?.filter(o => !o.isDXFFile);
            let result: any = [];
            if (techDrawings.length) {

              techDrawings.forEach(drawing => {
                const node = {
                  id: drawing.id,
                  title: drawing.fileName,
                  children: drawing.labels || [],
                };
                if (node.children.length) {
                  result.push(node)
                }
              });
              result.push({
                id: 'unlabelled',
                title: 'Unlabelled',
                children: [],
              })
              if (result.length) {
                this.getLinkImages(result[0].id);
              }
            }

            this.models = result;

          }, (error) => {
            throw error;
          });
      }
      else {
        // DXF Labels content
        this.labelSubscription = this.backendService
          .get2DDXFModels$(this.uiService.project?.assetId).pipe(
            takeUntil(this.onDestroy$)
          ).subscribe((dxfDrawing: any) => {
            this.dxfModel = dxfDrawing;
            if (this.dxfModel) {
              this.uiService.isGeoReference = dxfDrawing.isGeoreferenced;
              if (dxfDrawing.labelsFile) {
                this.backendService.getFile(dxfDrawing.labelsFile).subscribe((labels: any) => {
                  this.previousNode = labels;
                  this.models = JSON.parse(labels) || [];
                })
              }
              if (this.labelSubscription.closed) {
                this.getDXFLinkImages(this.dxfModel.id);
              }
            }
          });
      }


    } else {
      this.labelSubscription = this.backendService
        .get3DModels$(this.uiService.project?.assetId).pipe(
          takeUntil(this.onDestroy$)
        ).subscribe((model: any) => {
          this.uiService.model = model;
          if (model) {
            this.backendService.get3DModelLabels$(model.id).pipe(takeUntil(this.onDestroy$)).subscribe((labels: any) => {
              this.modelId = model.id;
              this.models = labels.label || [];
              if (this.models.length) {
                this.getLinkImages(this.modelId);
                this.models.push({
                  id: 'unlabelled',
                  title: 'Unlabelled'
                })
              }
            })
          }

        }), (error) => {
          throw error;
        }

    }
  }

  setRootFolder() {
    setTimeout(() => {
      if (document.getElementsByClassName('node-wrapper')[0] as any) {
        (document.getElementsByClassName('node-wrapper')[0] as any).click();
      }
    }, 1000);
  }

  setActiveFolder() {
    if (this.uiService.folderParams) {
      const splits = this.uiService.folderParams.folderPath.split("/");
      const folderId = splits[splits.length - 1];
      const node = this.treeComponent.treeModel.getNodeById(folderId);
      if (node) {
        clearInterval(this.activeFolderTimerId);
        node.expand();
        if (node.isLeaf) {
          node.parent.expand()
        }
        this.activeFolder = node;
      }
      if (!this.activeFolderTimerId) {
        // wait until loads all the async folders
        this.activeFolderTimerId = setInterval(() => {
          this.setActiveFolder();
        }, 500);
      }

    }
  }

  getGroups() {
    this.groupsSubscription = this.backendService.getProjectImageGroups(this.projectId)
      .pipe(
        takeUntil(this.onDestroy$)
      )
      .subscribe(groups => {
        this.groups = groups;
        this.uiService.groups$ = of(this.groups);
      });
  }

  getUnProcessedImages(): void {
    this.unprocessedImagesSubscription = this.backendService.getProjectUnprocessedImages(this.projectId).subscribe(images => {
      const unProcessedImages = [...new Set(images.map(o => o.imageId))]
      this.unProcessedImages = unProcessedImages;
      this.uiService.unProcessedImages$ = of(this.unProcessedImages);
    })
  }
  get3dExcludedImages(): void {
    this.exclude3DImagesSubscription = this.backendService.getExclude3DImage$(this.projectId).subscribe(images => {
      this.uiService.excluded3DIamges = images || [];
    })
  }

  groupImages = [];

  selectGroup(id: string): void {
    this.groupImages = [];
    this.uiService.groups$.pipe(takeUntil(this.onDestroy$)).subscribe(groups => {
      this.selectedGroup = id;
      this.clearFilters();
      const selectedGroup = groups.find(group => group.id === id);
      (selectedGroup && selectedGroup.images ? selectedGroup.images : []).map(imageId => {
        this.backendService.getImage$(imageId).subscribe((image: any) => {
          if (image && !image.deleted) {
            this.groupImages.push(image);
            this.groupImages = this.generalService.makeImageArrayUnique(this.groupImages)
          }
        })
      });
      this.uiService.selectedImagesIds$.next([]);
    });
  }

  linkedMedia;
  modelId;
  getLinkImages(modelId: string) {
    this.labelSubscription.unsubscribe();
    this.linkedMedia = null;
    this.labelSubscription = this.backendService
      .getLinkedImages$(this.uiService.project.assetId, modelId, this.uiService.project.id).subscribe((linkedMedia: any) => {
        this.linkedMedia = linkedMedia;
        if (linkedMedia) {
          let keys = Object.keys(linkedMedia);
          if (this.uiService.asset.baseModel == '2d') {
            this.models.forEach(model => {
              model.children.forEach(label => {
                delete label.color
                keys.forEach(element => {
                  if (element === label.id && linkedMedia[label.id].images) {
                    const labelImages = this.uiService.projectImageContexts.filter(item => linkedMedia[label.id].images.includes(item.id));
                    const maxArr = labelImages.map(x => x.tags?.filter(x => x.sensitive)?.map(x => x.sensitive))
                    const max = Math.max(...[].concat(...maxArr))
                    const tags = labelImages.map(x => x.tags.find(e => e.sensitive == max)).filter(x => x !== undefined)
                    if (tags.length) {
                      const findTag = this.uiService.tags.find(x => x.tag == tags[0]?.tag && x.status == 'active')
                      if (findTag) {
                        label.color = findTag.levels?.find(o => o.level == max)?.color
                      }
                    }
                  }
                })

              });
            });

          } else {
            this.models.forEach(label => {
              delete label.color
              keys.forEach(element => {
                if (element === label.id && linkedMedia[label.id].images) {
                  const labelImages = this.uiService.projectImageContexts.filter(item => linkedMedia[label.id].images.includes(item.id));
                  const maxArr = labelImages.map(x => x.tags?.filter(x => x.sensitive)?.map(x => x.sensitive))
                  const max = Math.max(...[].concat(...maxArr))
                  const tags = labelImages.map(x => x.tags.find(e => e.sensitive == max)).filter(x => x !== undefined)
                  if (tags.length) {
                    const findTag = this.uiService.tags.find(x => x.tag == tags[0]?.tag && x.status == 'active')
                    if (findTag) {
                      label.color = findTag.levels?.find(o => o.level == max)?.color
                    }
                  }
                }
              })
            });

          }
        }

      }, (error) => {
        throw error;
      });

  }

  mediaUpdates(modelId) {
    this.getDXFLinkImages(modelId)
  }

  dxfLinkRef;
  getDXFLinkImages(modelId: string) {
    this.linkedMedia = null;
    this.labelSubscription.unsubscribe();
    this.labelSubscription = this.backendService
      .getLinkedImages$(this.uiService.project.assetId, modelId, this.uiService.project.id).subscribe((linkImages: any) => {
        this.dxfLinkRef = linkImages;
        if (linkImages) {
          if (linkImages.linkFile) {
            this.backendService.getFile(linkImages.linkFile).subscribe((medias: any) => {
              this.linkedMedia = JSON.parse(medias) || [];
            })
          }

        }
      }, (error) => {
        throw error;
      });
  }

  selectTab(event): void {
    const tab: 'folders' | 'labels' | 'groups' = event.tab.textLabel.toLowerCase();
    this.selectedTab = tab;
    this.clearFilters();
    if (tab === 'labels') {
      this.router.navigate([`/dashboard/projects/${this.projectId}`]);
    }
    if (tab === 'groups') {
      if (this.selectedGroup) {
        this.selectGroup(this.selectedGroup);
      } else if (this.groups.length > 0) {
        this.selectGroup(this.groups[0].id);
      }
    } else {
      this.startIndex = 0;
      this.endIndex = 32;
      this.filteredImages = this.images.slice(this.startIndex, this.endIndex)
    }
  }

  selectFolderTab() {
    this.selectedTab = 'folders';
    this.clearFilters();
    this.selectedIndex = 0;
    if (this.selectedLabelNode) {
      this.selectedLabelNode.setIsActive(false);
      this.selectedLabelNode = null;
      this.linkedMedia = [];
    }


    //  this.getFolders();
  }

  cancelClickEvent(event) {
    if (!event.target.className.includes('mat-tab-group') || this.selectedTab === 'groups') {
      event.stopPropagation();
    }
  }

  handleProjectChange(e): void {
    this.router.navigate([`/dashboard/projects/${e.value}`]);
  }

  handleAddFolder(): void {
    let doc;
    let folders = [];
    if (this.uiService.activeFolder && this.uiService.activeFolder.isActive) {
      doc = this.uiService.activeFolder.data;
      folders = doc.children.map((folder) => folder.name);
    } else {
      doc = { path: `projects/${this.projectId}` };
      folders = this.nodes.map((node) => node.name);
    }

    const dialogRef = this.dialog.open(ProjectAddFolderComponent, {
      width: '510px',
      data: {
        doc,
        folders,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        if (this.uiService.activeFolder && this.uiService.activeFolder.isActive) {
          doc.children = [
            ...doc.children,
            {
              name: result.name,
              doc: { name: result.name },
              path: result.path,
              id: result.id,
              breadcrumb: {
                name: `${this.uiService.activeFolder.data.breadcrumb.name}/${result.name}`,
              },
              children: [],
            },
          ].sort((a, b) => a.name.localeCompare(b.name));
          this.nodes = [...this.nodes];
        } else {
          this.nodes = [
            ...this.nodes,
            {
              name: result.name,
              doc: { name: result.name },
              path: result.path,
              id: result.id,
              breadcrumb: {
                name: `${result.name}`,
              },
              children: [],
            },
          ].sort((a, b) => a.name.localeCompare(b.name));
        }

        if (
          this.activeFolder &&
          !this.activeFolder.treeModel.getFocusedNode().isExpanded
        ) {
          setTimeout(() => {
            this.activeFolder.treeModel.getFocusedNode().toggleExpanded();
          }, 200);
        }
      }
    });
  }

  handleAddGroup(): void {
    const dialogRef = this.dialog.open(ProjectAddGroupComponent, {
      width: '510px',
      data: {
        projectId: this.projectId,
        groups: this.groups
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getGroups();
    });
  }

  handleClick(node): void {
    this.clearFilters();
    if (node.data?.id !== this.uiService.activeFolder?.data?.id) {
      this.activeFolder = node;
      this.uiService.folderSelectedEvent$.next();
    }
  }

  handleLabelClick(node): void { }


  onContextMenu(event: MouseEvent, item: any, type: 'group' | 'folder'): void {
    event.preventDefault();
    console.log('onContextMenu', event, item, type);
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    item.__type = type;
    if (this.contextMenu) {
      this.contextMenu.menuData = { item };
      this.contextMenu.menu.focusFirstItem('mouse');
      this.contextMenu.openMenu();
    }
  }

  handleRenameFolder(folder) {
    const doc = { path: `projects/${this.projectId}` };
    const folders = this.nodes.map((node) => node.name);

    const dialogRef = this.dialog.open(ProjectRenameFolderComponent, {
      width: '510px',
      data: {
        projectId: this.projectId,
        folder,
        folders
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.success) {
        folder.data.name = folder.data.doc.name = result.name;
        let split = folder.data.breadcrumb.name.split('/');
        split.splice(-1, 1);
        folder.data.breadcrumb.name = split.join("/") ? ("/" + result.name) : result.name;
        this.renameBreadcrumbSubFolers(this.activeFolder.data.children, folder.data.breadcrumb.name)
      }
    });
  }
  renameBreadcrumbSubFolers(subEl, breadcrumb) {
    subEl.forEach(element => {
      element.breadcrumb.name = breadcrumb + "/" + element.name;
      this.renameBreadcrumbSubFolers(element.children, element.breadcrumb.name)
    });
  }

  handleMoveFolder(folder: TreeModel) {
    this.movedFolder = folder;
  }

  handlePasteFolder(folder) {
    this.backendService.createFolder(folder.data.path, this.movedFolder.data.name).then((docRef) => {
      folder.data.children.push(this.movedFolder.data);
      this.treeComponent.treeModel.update();
      this.deleteFolder(this.movedFolder);
    });
  }

  deleteItem(item): void {
    if (item.__type === 'folder') {
      // this.deleteFolder(item);
      this.markFolderDeleted(item);
    } else {
      this.deleteGroup(item);
    }
  }

  private deleteFolder(item): void {
    console.log('deleteFolder', item);
    this.backendService.deleteDoc(item.data.path).subscribe((result) => {
      console.log(result);
    });
    item.hide();
    this.activeFolder = null;
  }

  private markFolderDeleted(item): void {
    this.backendService.markFolderDeleted(item, this.projectId).then(() => {
      item.hide();
      this.folderContentsSubscription.unsubscribe()
      this.activeFolder = null;
      this.filteredImages = this.images = [];
    });
  }

  private deleteGroup(group: any): void {
    this.backendService.removeImageGroup(group.id, this.projectId).subscribe();
    this.groups = this.groups.filter(item => group.id !== item.id);
  }

  selectProjectRoot(event): void {
    if (this.activeFolder) {
      this.activeFolder.setIsActive(false);
    }
    this._activeFolder = this.activeFolder = null;
    this.cdRef.markForCheck();
    event.stopImmediatePropagation();
  }


  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    clearInterval(this.timerId);
    clearInterval(this.activeFolderTimerId);
    this.uiService.project = null;
    this.folderSubscription.unsubscribe();
    this.groupsSubscription.unsubscribe();
    this.folderContentsSubscription.unsubscribe();
    this.videoContentsSubscription.unsubscribe();
    this.tagSubscription.unsubscribe();
    this.projectSubscription.unsubscribe();
    this.tagsSubscription.unsubscribe();
    this.labelSubscription.unsubscribe();
    this.aiEventSubscription.unsubscribe();
    this.selectedSubscription.unsubscribe();
    this.folderChange.unsubscribe();
    this.exclude3DImagesSubscription.unsubscribe();
    this.uiService.imagesAnnotations$.unsubscribe();
  }


  showImageDialog(): void {
    const data: ImageTitleImageData = {
      projectId: this.projectId,
      groupId: this.selectedGroup
    };
    const dialogRef = this.dialog.open(ImageTitleImageDialogComponent, {
      width: '70vw',
      height: '100vh',
      panelClass: 'no-border-radius-dialog',
      data,
    });

    const subscr = dialogRef.afterClosed().subscribe(() => {
      subscr.unsubscribe();
      this.selectGroup(this.selectedGroup);
    });
  }

  groupImagesChanged(images: any[]): void {
    this.selectedGroupImages = images;
    console.log({ images });
  }

  deleteImagesFromGroup(): void {
    const selectedGroupObj = this.groups.find(group => group.id === this.selectedGroup);
    this.backendService.addHistory(this.selectedGroupImages, this.projectId, `image unassigned from group ${selectedGroupObj.groupName}`, "group", selectedGroupObj)
    this.backendService.removeImagesGroup$(selectedGroupObj, this.selectedGroupImages, this.projectId).subscribe(() => {
      this.selectGroup(this.selectedGroup);
      this.selectedGroupImages = [];
    });
  }

  getCurrentUrl(): string {
    return this.router.url;
  }

  getReadOnlyForCurrentUser(): boolean {
    return this.generalService.getReadOnlyForCurrentUser();
  }

  checkTopFolderSeverity(node) {
    if (node) {
      const folderImages = this.uiService.projectImageContexts?.filter(o => o.folderPath == node.data.path)
      if (folderImages) {
        const maxArr = folderImages.map(x => x.tags?.filter(x => x.sensitive)?.map(x => x.sensitive))
        const max = Math.max(...[].concat(...maxArr))
        const tags = folderImages.map(x => x.tags.find(e => e.sensitive == max)).filter(x => x !== undefined)
        if (tags.length) {
          const findTag = this.uiService.tags.find(x => x.tag == tags[0]?.tag && x.status == 'active')
          if (findTag) {
            return findTag.levels?.find(o => o.level == max)?.color;
          }
        }
      }
    }
  }


}
