import { Component, Input,OnInit, ElementRef, AfterViewInit } from '@angular/core';
import Konva from 'konva';
@Component({
  selector: 'app-bounding-box',
  templateUrl: './bounding-box.component.html',
  styleUrls: ['./bounding-box.component.scss']
})
export class BoundingBoxComponent implements OnInit,AfterViewInit  {
  @Input() image: any;
  containerId: string;
  constructor(private el:ElementRef) { 

  }

  ngOnInit(): void {

  }


  ngAfterViewInit(): void {
    if(!this.image){
      return;
    }
    const img =this.image;
    const uniqueId =this.image.uuid;
      const container= document.getElementById(`${uniqueId}`);
    //  this.el.nativeElement.querySelector(`#${uniqueId}`);
      if (!container) {
        console.error('Container not found!');
        return;
      }

      this.image.thumbFileSize.height= (this.image.originalFileSize.height/this.image.originalFileSize.width) * this.image.thumbFileSize.width;
      const stage = new Konva.Stage({
        container: container.id,
        width: this.image.thumbFileSize.width,
        height:  this.image.thumbFileSize.height,
      });
  
      const layer = new Konva.Layer();
      stage.add(layer);
  
      const image = new Image();
      image.onload = () => {
        const konvaImage = new Konva.Image({
          image,
          width: stage.width(),
          height: stage.height(),
        });
  
        layer.add(konvaImage);
  
        if (img.panel && img.panel.images && img.panel.images[img.id]) {
          this.drawBoundingBox(img,layer);
        }
  
        stage.draw();
      };
      image.src = this.image.colorThumbFileUrl || this.image.thumbFileUrl;  
  }

  private convertScalePointsToThumb(points,image){
    var scaleX =  image.thumbFileSize.width / image.originalFileSize.width;
    var scaleY = image.thumbFileSize.height/image.originalFileSize.height ;
   return  points.map((point, i) => point * (i % 2 ? scaleX : scaleY));
  }

  private drawBoundingBox(image,layer): void {
    const points = image.panel.images[image.id].points;
    const convertedPoints = this.convertScalePointsToThumb(points,image)
    //this.boundingBox;
    const polyLine = new Konva.Line({
      points: convertedPoints,
      stroke: 'white',
      strokeWidth: 2,
      closed: true,
    });
    layer.add(polyLine);
    // Draw the layer
    layer.draw();
  }
}
