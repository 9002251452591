<div class="d-flex h-100">
  <!-- --------------left menu-------- -->
  <app-asset-sidebar *ngIf="backendService.currentUserData" (assetsChange)="updateAssets($event)
" (loadMap)="mapInit()" (projectChange)="projectChange($event)" (allProjects)="allProjects($event)"
    (title)="projectTitle($event)" (menuChange)="toggle()" (companyAssetChange)="companyAssetChange($event)">

  </app-asset-sidebar>
  <!-- --------------right menu-------- -->
  <ng-container>
    <ng-container [ngTemplateOutlet]="projectList"></ng-container>
  </ng-container>

  <ng-template #projectList>
    <div class="justify-content-center w-100 bg">
      <headings class="headings ml-auto mr-auto py-2 w-100">
        <span class="mr-3 title">{{title}}</span>
        <div class="d-flex side-right ml-auto">
          <button [class.isActive]="isSearch"
            *ngIf="(uiService.selectedSideTab =='assets' && (projects && projects.length > 0)  && assetSidePanelView !='map' && assetSidePanelView !='timeline')
            ||
            (uiService.selectedSideTab =='campaigns' && uiService.campaignAssetView && uiService.selectedCampaignNode.asset)
            ||
            (uiService.selectedSideTab =='campaigns' && !uiService.campaignAssetView && uiService.selectedCampaignCompany.asset)
            ||
           (uiService.selectedSideTab =='projects' && (projects && projects.length > 0) && projectSidePanelView != 'inspection')"
            (click)="searchToggle()" mat-raised-button class="mr-2 search">
            <mat-icon>search</mat-icon>
          </button>

          <button (click)="viewAssetInfo()" class="image" *ngIf="(uiService.selectedSideTab =='assets' && companyAssets.length) || 
            (uiService.selectedSideTab =='campaigns' && uiService.campaignAssetView && uiService.selectedCampaignNode.asset)
            ||
            (uiService.selectedSideTab =='campaigns' && !uiService.campaignAssetView && uiService.selectedCampaignCompany.asset)
            " mat-raised-button class="mr-2 header-button">
            <img src="assets/assets-info.svg" width="22">
            <span class="ml-1 text-uppercase">Assets Info</span>
          </button>

          <button *ngIf="uiService.selectedSideTab !='campaigns'" class="mr-2 header-button" mat-raised-button
            (click)="handleAddAsset()">
            <mat-icon class="mr-1">add_circle_outline</mat-icon>
            <span class="text-uppercase">New Asset</span>
          </button>

          <button *ngIf="(uiService.selectedSideTab =='assets' &&  this.uiService.selectedAssetNode.child?.id) || 
            (uiService.selectedSideTab =='campaigns' && uiService.campaignAssetView && uiService.selectedCampaignNode.asset)
            ||
            (uiService.selectedSideTab =='campaigns' && !uiService.campaignAssetView && uiService.selectedCampaignCompany.asset)
            " mat-raised-button class="mr-2 header-button dark" (click)="handleAdd()">
            <mat-icon class="mr-1">library_add</mat-icon>
            <span class="text-uppercase">New Project</span>
          </button>

          <button *ngIf="uiService.selectedSideTab =='campaigns'" mat-raised-button class="mr-2 header-button dark"
            (click)="handleAddCampaign()">
            <mat-icon class="mr-1">library_add</mat-icon>
            <span class="text-uppercase">Create Campaign</span>
          </button>
        </div>
      </headings>
     
      <ng-container *ngIf="filteredProjects">
        <div class="projects-container">
          <div style="height:55px;" class="row w-100">
            <div class="col-9 col-search" id="col-search">
              <mat-form-field *ngIf="isSearch" style="color: #606060;" class="w-100 mt-2 mb-2 ml-3 mr-3"
                appearance="fill">
                <mat-icon matPrefix>search</mat-icon>
                <input matInput cdkFocusInitial [(ngModel)]="search" placeholder="Search by project name or inspection date" />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-button-toggle-group vertical="false" [(ngModel)]="assetSidePanelView" class="group-right mt-2" *ngIf="getAssetId()">
                <mat-button-toggle class="toggle-btn" matTooltip='Panel view' (change)="onValChange($event.value)"
                  [class.btn_active]="assetSidePanelView === 'panel'" value="panel">
                  <mat-icon>view_module_outline</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle class="toggle-btn" matTooltip='Map view'
                  [class.btn_active]="assetSidePanelView === 'map'" (change)="onValChange($event.value)" value="map">
                  <mat-icon>map_outline</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle [disabled]="!validateAsset()" class="toggle-btn"
                  *ngIf="companyAssets.length   && backendService.DOMAIN_CONTROL?.features?.sideBySide"
                  [class.btn_active]="assetSidePanelView === 'timeline'" matTooltip='{{getToolTip()}}'
                  (change)="onValChange($event.value)" value="timeline">
                  <mat-icon>
                    <img src="assets/timeline.png" />
                  </mat-icon>
                </mat-button-toggle>
              </mat-button-toggle-group>

              <mat-button-toggle-group vertical="false" [(ngModel)]="projectSidePanelView" class="group-right mt-2"
                *ngIf="uiService.selectedSideTab =='projects'">
                <mat-button-toggle class="toggle-btn" matTooltip='Panel view'
                  [class.btn_active]="projectSidePanelView === 'panel'" (change)="projectPanelValChange($event.value)"
                  value="panel">
                  <mat-icon>view_module_outline</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle class="toggle-btn" matTooltip='Inspection view'
                  [class.btn_active]="projectSidePanelView === 'inspection'"
                  (change)="projectPanelValChange($event.value)" value="inspection">
                  <mat-icon>calendar_today</mat-icon>
                </mat-button-toggle>

              </mat-button-toggle-group>

            </div>
          </div>


          <!-- -------- Project panel View----------- -->
          <div [hidden]="(uiService.selectedSideTab =='assets' && assetSidePanelView =='map' || assetSidePanelView =='timeline')
          || (uiService.selectedSideTab =='projects' && projectSidePanelView === 'inspection')"
            class="project-list-container mt-2">
            <ng-container *ngFor="let p of filteredProjects">
              <projects-item [assets]="assets" [companyAssets]="companyAssets" [selectedTab]="uiService.selectedSideTab"
                [view]="assetSidePanelView" [project]="p" [readonly]="getReadOnlyForCurrentUser(p)"
                *ngIf="p.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || 
                (p.inspectionDate | date: 'dd/MM/yyyy')?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase())"
                (renameEvent)="openRenameDialog(p)" class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3"></projects-item>
            </ng-container>
          </div>

          <!-- -------- Map View----------- -->
          <div *ngIf="assets.length" [hidden]="uiService.selectedSideTab =='projects' || assetSidePanelView != 'map' "
            class="project-map-container">
            <div [hidden]="!assetMapData" #mapProjectEl id="mapProjectEl"></div>
            <h1 *ngIf="!assetMapData" class="text-center mt-5"> No locations set for any asset yet.</h1>
          </div>



          <!-- -------- Inspection View----------- -->
          <div [hidden]="!assets.length || uiService.selectedSideTab =='assets' ||  projectSidePanelView === 'panel'"
            class="inspection">
            <full-calendar #calendar [options]="calendarOptions"></full-calendar>
          </div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="projects && projects.length <= 0 &&  companyAssets.length > 0 && !(isProjectsLoading$ | async) && uiService.selectedSideTab =='assets' && assetSidePanelView ==='panel'">
        <div class="d-flex mt-3 align-items-center flex-column">
          <img src="../../assets/empty-project.svg" alt="ADD PROJECT">
          <button mat-raised-button color="primary" (click)="handleAdd()" class="mb-0 mt-5">
            <span class="text-uppercase">START BY ADDING A NEW PROJECT</span>
          </button>
        </div>

      </ng-container>

      <ng-container *ngIf="companyAssets.length === 0 &&  
        !(isProjectsLoading$ | async) && 
        ((uiService.selectedSideTab =='assets' && assetSidePanelView ==='panel') || (uiService.selectedSideTab =='projects' && projectSidePanelView ==='panel'))
        ">
        <div class="d-flex mt-3 align-items-center flex-column">
          <img src="../../assets/empty-project.svg" alt="ADD PROJECT">
          <button mat-raised-button color="primary" (click)="handleAddAsset()" class="mb-0 mt-5">
            <span class="text-uppercase">START BY ADDING A NEW ASSET</span>
          </button>
        </div>
      </ng-container>
    </div>
  </ng-template>


  <ng-template #deleteDialog let-data>
    <p style="font-size: 18px;">This is irreversible. Are you sure you want to delete the project?</p>
    <p style="font-size: 16px;">Please, type <b>{{data.project.name}}</b> to confirm:</p>
    <mat-form-field class="w-100 mb-2" appearance="fill">
      <input matInput cdkFocusInitial [(ngModel)]="projectNameInput" placeholder="Project Name" autocomplete="false" />
    </mat-form-field>

    <div class="d-flex align-items-center justify-content-end mt-3">
      <button mat-button (click)="closeDeleteDialog()">Cancel</button>
      <button [disabled]="data.project.name !== projectNameInput" mat-button class="ml-2" color="warn" mat-raised-button
        (click)="deleteProject(data.project)">
        Delete
      </button>
    </div>
  </ng-template>

  <ng-template #renameDialog let-data>
    <div class="popup-header w-100 d-flex">
      <p class="w-100">
        Renaming the project
      </p>
      <mat-icon class="close-btn" (click)="renameDialogRef.close()">close</mat-icon>
    </div>
    <div>
      <div mat-dialog-content class="mb-5">
        <mat-form-field class="w-100 mb-2 mt-4" appearance="fill">
          <input matInput cdkFocusInitial [(ngModel)]="projectNameInput" placeholder="Project Name"
            autocomplete="false" />
        </mat-form-field>
        <br>
        <div *ngIf="renameError" class="rename-alert-error">
          {{ renameError }}
        </div>
      </div>
      <div mat-dialog-actions>
        <button mat-button (click)="renameProject(data.project.id, projectNameInput)" class="ml-2" color="primary"
          mat-raised-button>
          Save
        </button>
        <button class="ml-2" mat-button (click)="renameDialogRef.close()">Cancel</button>
      </div>

    </div>
  </ng-template>
  <ng-template *ngIf="(isProjectsLoading$ | async)">
    <div class="h-100 w-100 flex align-center align-middle">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
  </ng-template>
</div>
<ng-template #calendarPopover>
  <h3></h3>
</ng-template>


<ng-template #projectSummary let-data>
  <div class="popup-header d-flex">
    <p class="w-100">
      Summary
    </p>
    <mat-icon class="close-btn" (click)="summaryDialogRef.close()">close</mat-icon>
  </div>

  <div mat-dialog-content>
    <div>
      <mat-card-title class="text-center wrap-line">
        {{data.asset.assetName}}
      </mat-card-title>
      <mat-card-subtitle classs="wrap-line">
        {{data.asset.companyName}}
      </mat-card-subtitle>
      <mat-card-subtitle classs="wrap-line">
        {{data.asset.assetContactName}}
      </mat-card-subtitle>
    </div>
    <table class="table-summary w-100" *ngIf="data.projects.length">
      <thead>
        <tr>
          <td>
            Project Name
          </td>
          <td>
            Role
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let project of data.projects">
          <td>
            <p class="wrap-line">
              {{ project.name }}
            </p>
          </td>
          <td>
            <ng-container [ngSwitch]="getRoleOfProject(project)">
              <ng-container *ngSwitchCase="'owner'">Owner</ng-container>
              <ng-container *ngSwitchCase="'editor'">Editor</ng-container>
              <ng-container *ngSwitchCase="'reviewer'">Reviewer</ng-container>
              <ng-container *ngSwitchCase="'approver'">Approver</ng-container>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
    <button *ngIf="data.projects.length >= 2 && showTimeLine" class="timeline-btn" mat-raised-button color="primary"
      (click)="summaryDialogRef.close();handleCompare(data.asset.id)">
      <mat-icon>
        <img src="assets/timeline.png" />
      </mat-icon>
      <span class="text-uppercase">Timeline</span>
    </button>
  </div>

</ng-template>