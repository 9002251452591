import { Component, ElementRef, Inject, OnInit, ViewChild, TemplateRef, OnChanges, ChangeDetectorRef, AfterViewChecked, ViewEncapsulation, OnDestroy } from '@angular/core';
//import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { BackendService } from '../../services/backend.service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { v4 as uuidv4 } from 'uuid';
import { UiService } from 'src/app/services/ui.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { WaMatConfirmDialog } from '@webacad/material-confirm-dialog';
import { take } from 'rxjs/operators';
declare var google;
const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {});
};
@Component({
  selector: 'app-asset-form',
  templateUrl: './asset-form.component.html',
  styleUrls: ['./asset-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AssetFormComponent implements OnInit, OnChanges, OnDestroy, AfterViewChecked {
  @ViewChild('mapEl') mapElement: any;
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  @ViewChild('assetTransferDialog') assetTransferDialog: any;
  public assetTransferDialogRef: any;
  map: any;
  marker;
  public name: string;
  public industry: string;
  companies = [];
  contacts = [];
  userIndustries = [];
  assets = [];
  public assetData;
  assetsImage: any;
  assetsPic: any;
  form: FormGroup;
  isValidLatitude: boolean = true;
  isValidLongitude: boolean = true;
  projects = [];
  public data: any;
  public industrySubscription: Subscription;
  assetOwner: any;
  public isAssetsImageLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  //public isTechnicalDrawingLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public createBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Save',
    raised: true,
    spinnerSize: 24,
    spinnerColor: 'primary',
    buttonIcon: {
      color: 'primary',
      fontIcon: 'save',
      inline: false
    },
    customClass: 'text-uppercase'
  };

  public createSaveBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Save',
    raised: true,
    spinnerSize: 24,
    spinnerColor: 'primary',
    buttonIcon: {
      color: 'primary',
      fontIcon: 'save',
      inline: false
    },
    customClass: 'text-uppercase'
  };

  public createSaveWithCancelBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Save & Close',
    raised: true,
    spinnerSize: 24,
    spinnerColor: 'primary',
    buttonIcon: {
      color: 'primary',
      fontIcon: 'save',
      inline: false
    },
    customClass: 'text-uppercase'
  };

  public inspectionSaveBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Save',
    raised: true,
    spinnerSize: 20,
    spinnerColor: 'primary',
    buttonIcon: {
      color: 'primary',
      fontIcon: 'save',
      inline: false
    },
    customClass: 'text-uppercase'
  };

  public deleteAssetBtnOptions: MatProgressButtonOptions = {
    active: false,
    raised: true,
    text: 'Delete',
    spinnerSize: 20,
    spinnerColor: 'warn',
    buttonIcon: {
      color: 'warn',
      fontIcon: 'delete_outline',
      inline: false
    },
    customClass: 'text-uppercase'
  };

  inspection = {
    frequency: "",
    startDate: "",
    endDate: "",
    frequency_number: 1,
    color: this.generateRandomColor(),
  };
  tab = 0;
  tabIndex = 0;
  public isAssetOwner: boolean = false;
  constructor(
    public uiService: UiService,
    public dialog: MatDialog,
    private router: Router,
    public backend: BackendService, public formBuilder: FormBuilder,
    // public dialogRef: MatDialogRef<AssetFormComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    public toast: ToastrService
    , private cd: ChangeDetectorRef,
    private confirmDialog: WaMatConfirmDialog
  ) {

    this.industrySubscription = this.backend.valueChangesIndustries().subscribe(data => {
      if (data.industries) {
        this.userIndustries = data.industries;
      }
    });
    this.form = this.formBuilder.group({
      latitude: [''],
      longitude: [''],
      assetName: ['', Validators.required],
      assetTags: ['', Validators.required],
      assetId: ['', Validators.required],
      assetNumber: [''],
      eqClass: [''],
      eqSubClass: [''],
      dateOfComm: [''],
      companyName: ['', Validators.required],
      address: [''],
      city: [''],
      state: [''],
      country: [''],
      zip: [''],
      phone: [''],
      fax: [''],
      contactName: ['', Validators.required],
      location: [''],
      contactPersonLocation: [''],
      department: ['', Validators.required],
      email: ['', Validators.required],
      workContact: [''],
      mobileContact: ['', Validators.required],
      fileName: [''],
      id: [''],
      createdBy: [''],
      industry: ['', Validators.required],
      assetType: ['', Validators.required],
      baseModel: [''],
      baselineProjectId: ['']
    });
    if (this.router.getCurrentNavigation()) {
      const routeState = this.router.getCurrentNavigation().extras.state;
      if (routeState) {
        this.data = JSON.parse(routeState.data);
        this.formSet();
        this.backend.getAllUserAssets().subscribe(data => {
          const companies = this.data.companyAssets;
          if (data) {
            this.assets = data;
            this.groupWithAddress(companies);
            this.groupWithContacts(this.assets);
          }
        })
      }
    } else {
      this.close();
    }
  }

  verifyUserIsAssetOwner() {
    const owners = this.projects.filter(project => project.people[this.backend.currentUser.uid].role == "owner");
    if (owners.length > 0 || this.form.value.createdBy == this.backend.currentUser.uid) {
      this.isAssetOwner = true;
    } else {
      this.form.disable();
    }
    this.getOwner(this.form.value.createdBy);
  }

  getOwner(id) {
    this.backend.getUser(id).pipe(take(1)).subscribe(user => {

      this.assetOwner = {
        id: user.id,
        name: user.data().firstName ? (user.data().firstName + " " + user.data().lastName) : user.data().displayNamed
      };
    })
  }

  modelChange(event) {
    if (this.data.id) {
      const params = {
        id: this.data.id,
        baseModel: event.value
      }
      this.backend.addOrEditAssets(params).subscribe(response => {
        this.assetData.baseModel = event.value;
      })
    }

  }

  ngOnChanges() {
    this.cd.detectChanges();
  }

  ngAfterViewInit() {
    this.initSearch()
  }

  ngOnDestroy(): void {
    if (this.industrySubscription) {
      this.industrySubscription.unsubscribe();
    }
  }


  ngOnInit(): void {

  }

  formSet() {
    if (this.data.id) {
      this.assetData = this.data.assets.find(o => o.id == this.data.id);
      this.form.patchValue(this.assetData);
      this.projects = this.data.projects.filter(o => o.assetId == this.data.id);
      this.verifyUserIsAssetOwner();
      if (this.assetData.latitude && this.assetData.longitude) {
        setTimeout(() => {
          this.loadMap(this.assetData.latitude, this.assetData.longitude)
        }, 2000);
      } else {
        this.currentLocation();
      }
      if (this.assetData.assetsImage) {
        this.assetsPic = this.assetData.assetsImage;
      }
      if (this.assetData.inspection) {
        this.inspection = this.assetData.inspection;
      }
    } else {
      this.currentLocation();
      this.backend.getTagNmber().subscribe(assetTag => {
        this.form.get('assetTags').setValue(`DIVE-AT-${(parseInt(assetTag.docs.length) + 1).toLocaleString('en-US', {
          minimumIntegerDigits: 7,
          useGrouping: false
        })}`);
      })
    }
  }

  groupWithAddress(companies) {
    this.companies = [];
    companies.forEach(company => {
      const filterAsset = this.assets.filter(o => o.companyId == company.id);
      if (filterAsset.length) {
        // group company Name to location
        const groupAddress = groupBy(filterAsset, 'address');
        let keys = Object.keys(groupAddress)
        keys.forEach(key => {
          this.companies.push({
            id: company.id,
            companyName: company.companyName,
            asset: groupAddress[key][0]
          })
        });
      }
    });
  }

  isLoading: boolean = false;
  isConflict: boolean = false;
  changeBaselineProject(id) {
    if (!this.data.id || !id) { return }
    this.isConflict = false;
    const project = this.uiService.allProjects.find(o => o.id === id);
    if (project && project.tileStatus == 'success') {
      this.isLoading = true;
      this.backend.get3DModels(this.data.id).subscribe((result: any) => {
        this.isLoading = false;
        if (!result || !result.tileStatus || result.tileStatus == 'failed') {
          {
            this.confirmDialog
              .open(
                `This project already has a 3D model. Would you like to make it a baseline model.`,
                {
                  trueButtonTitle: 'Yes',
                  falseButtonTitle: 'No'

                }
              )
              .afterClosed()
              .subscribe((ok: boolean) => {
                if (ok) {
                  const data = {
                    gltfUrl: project.gltfUrl,
                    tileStatus: project.tileStatus,
                    ownModel: project.ownModel,
                    size: project.size,
                    model_zipfileUrl: project.model_zipfileUrl
                  }
                  if (!result) {
                    this.backend.link3DModel$(this.data.id, data).subscribe(response => { });
                  } else {
                    this.backend.updateLink3DModels$(result.id, data).subscribe(response => { });
                  }
                  const params = {
                    id: this.data.id,
                    baselineProjectId: id
                  }
                  this.backend.addOrEditAssets(params).subscribe(() => {
                    this.assetData.baselineProjectId = id
                    this.toast.success("Project 3D marked as a baseline")

                  })
                }
              });
          }
        } else {
          this.isConflict = true;
        }
      })
    } else {
      if (this.assetData.baselineProjectId && this.assetData.baselineProjectId != id) {
        this.toast.warning("Changing the baseline project will loose all your previously added linked images")
      }
    }

  }

  groupWithContacts(assets) {
    this.contacts = [];
    const groupContacts = groupBy(assets, 'email');
    let keys = Object.keys(groupContacts)
    keys.forEach(key => {
      this.contacts.push(groupContacts[key][0])
    });
  }

  currentLocation() {
    let me = this;
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };
    function success(pos) {
      var crd = pos.coords;
      me.loadMap(crd.latitude, crd.longitude);
    }
    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }
    navigator.geolocation.getCurrentPosition(success, error, options);

  }

  close(): void {
    this.router.navigateByUrl('dashboard/projects')
  }

  filterName(value: string) {
    const filterValue = value.toLowerCase();
    this.groupWithAddress(this.data.companyAssets.filter(o => o.companyName.toLowerCase().includes(filterValue)));
  }

  filterContactName(value: string) {
    const filterValue = value.toLowerCase();
    this.groupWithContacts(this.assets.filter(o => o.contactName.toLowerCase().includes(filterValue)));
  }

  selectCompany(company) {
    this.form.get('companyName').setValue(company.companyName);
    if (company.asset) {
      const address = company.asset;
      this.form.get('address').setValue(address.address)
      this.form.get('city').setValue(address.city)
      this.form.get('state').setValue(address.state)
      this.form.get('country').setValue(address.country)
      this.form.get('zip').setValue(address.zip)
      this.form.get('phone').setValue(address.phone)
      this.form.get('fax').setValue(address.fax)
    }

  }

  selectContact(contact) {
    this.form.get('contactName').setValue(contact.contactName);
    this.form.get('contactPersonLocation').setValue(contact.contactPersonLocation)
    this.form.get('department').setValue(contact.department)
    this.form.get('email').setValue(contact.email)
    this.form.get('workContact').setValue(contact.workContact)
    this.form.get('mobileContact').setValue(contact.mobileContact)
  }



  validate(formValue) {
    let params = formValue;
    this.createBtnOptions.active = true;
    if (this.assetsPic && this.assetsPic.isUploaded) {
      params["assetsImage"] = this.assetsPic;
    }
    const findCompany = this.data.companyAssets.find(o => o.companyName == formValue.companyName)
    if (findCompany || this.data.companyId) {
      if (findCompany) {
        params["companyId"] = findCompany.id;
      } else {
        params["companyId"] = this.data.companyId;
      }
      this.updateAssets(params);
    } else {
      this.backend.createAssetCompany(formValue.companyName).pipe().subscribe(response => {
        this.data.companyId = params["companyId"] = response.id;
        this.updateAssets(params);
      })
    }
  }

  updateAssets(params) {
    params.id = this.data.id || "";
    this.backend.addOrEditAssets(params).subscribe(response => {
      this.uiService.asset = params;
      if (this.data.linkProjectId) {
        this.backend.linkAssetToProject(this.data.linkProjectId, response.id).subscribe(() => {
          this.createBtnOptions.active = false;
          this.close()
        })
      }
      /*else if (this.data.id && this.assetData.baselineProjectId && this.assetData.baselineProjectId != params.baselineProjectId) {
          this.backend.removeBaselineProjectLinkImages(this.data.id);
        this.createBtnOptions.active = false;
      //  this.close();
      }*/
      else {
        this.createBtnOptions.active = false;
        this.assetData = this.form.value;
        if (response && response.id) {
          this.form.value.id = this.data.id = response.id;
          this.form.value.createdBy = this.backend.getCurrentUser().uid;
          this.isAssetOwner = true;
          this.getOwner(this.backend.currentUser.uid);
          this.toast.success("asset created successfully");
        } else {
          this.toast.success("asset updated successfully");
        }
      }

    })
  }

  /* uploadTechnicalDrawing() {
     if (this.technicalDrawing.length == 4) {
       this.toast.error('You have already uploaded max (5) technical files');
     }
     else {
       this.isTechnicalDrawingLoading$.next(true)
       const file = this.technicalFile;
       this.backend.uploadTechnicalDrawing(file).pipe().subscribe(response => {
         this.isTechnicalDrawingLoading$.next(false);
         this.technicalDrawing.push({
           link: response.link,
           id: uuidv4(),
           fileName: this.form.get('fileName').value
         })
         this.form.get('fileName').setValue("");
         this.technicalFile = null;
       }, error => {
         this.isTechnicalDrawingLoading$.next(false);
         throw (error);
       })
 
     }
   }
 
   getFile(event) {
     this.technicalFile = event.target.files[0];
     this.form.get('fileName').setValue(this.technicalFile.name);
   }
 
 
   removeTechnicalDrawing(index) {
     this.technicalDrawing.splice(index, 1);
   }
 */
  assetFileChoose(event) {
    this.assetsImage = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (_event: any) => {
      this.assetsPic = {
        link: _event.target.result,
        isUploaded: false,
        name: this.assetsImage.name,
      }
      this.uploadAssetImageProcess();
    };
    reader.readAsDataURL(event.target.files[0]);
  }


  uploadAssetImage() {
    document.getElementById('asset_image').click();
  }

  uploadAssetImageProcess() {
    this.isAssetsImageLoading$.next(true)
    this.backend.uploadAssetsImage(this.assetsImage).pipe().subscribe(response => {
      this.isAssetsImageLoading$.next(false);
      this.assetsPic = {
        link: response.link,
        isUploaded: true,
        name: response.name,
      }
      if (this.data.id) {
        const params = {
          "assetsImage": this.assetsPic,
          id: this.data.id
        }
        this.backend.addOrEditAssets(params).subscribe(response => { })
      }

      this.assetsImage = null;
    }, error => {
      this.isAssetsImageLoading$.next(false);
      throw (error);
    })
  }

  initSearch() {
    const _this = this;
    var input = document.getElementById('locationTextField');
    const autocomplete = new google.maps.places.Autocomplete(input);
    google.maps.event.addListener(autocomplete, 'place_changed', function () {
      var place = autocomplete.getPlace();
      _this.tabIndex = 1;
      // iterate through address_component array
      place.address_components.forEach(address_component => {
        if (address_component.types[0] == "locality") {
          _this.form.get('location').setValue(address_component.long_name);
        }
        if (address_component.types[0] == "country") {
          _this.form.get('country').setValue(address_component.long_name);
        }
      });
      _this.form.get('latitude').setValue(place.geometry.location.lat());
      _this.form.get('longitude').setValue(place.geometry.location.lng());
      _this.updateMapOnMarker();
      setTimeout(() => {
        google.maps.event.trigger(_this.marker, 'mouseover');
      }, 500);

    });
  }
  loadMap(lat, lng) {
    let latLng = new google.maps.LatLng(lat, lng);
    let mapOptions = {
      center: latLng,
      zoom: 10,
      streetViewControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    }
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
    this.addMarker();

  }

  updateMapOnMarker() {
    const formValues = this.form.value;
    if (!formValues.latitude || !formValues.longitude) {
      return;
    }
    if (!this.map) {
      this.loadMap(formValues.latitude, formValues.longitude)
    } else {
      var latlng = new google.maps.LatLng(formValues.latitude, formValues.longitude);
      this.map.setCenter(latlng);
      this.marker.setPosition(latlng);
    }

  }
  addMarker() {
    this.marker = new google.maps.Marker({
      map: this.map,
      draggable: true,
      animation: google.maps.Animation.DROP,
      position: this.map.getCenter()
    });
    const me = this;
    google.maps.event.addListener(this.marker, 'dragend', function (marker) {
      me.form.get('latitude').setValue(marker.latLng.lat());
      me.form.get('longitude').setValue(marker.latLng.lng());
      me.map.setCenter(marker.latLng);
    })
    const infowindow = new google.maps.InfoWindow(
      {
        content: ``
      }
    );
    this.marker.addListener('mouseover', function () {
      if (me.form.get('latitude').value && me.form.get('longitude').value) {
        infowindow.setContent(`Lat:  ${me.form.get('latitude').value.toFixed(2)} <br> Long: ${me.form.get('longitude').value.toFixed(2)}`)
        infowindow.open(me.map, this);
      }

    });

    this.marker.addListener('mouseout', function () {
      infowindow.close();
    });
  }


  inspectionSave(inspection) {
    this.inspectionSaveBtnOptions.active = true;
    this.backend.inspectionSchedule(this.form.value.id, {
      startDate: inspection.startDate,
      endDate: inspection.endDate,
      frequency: inspection.frequency,
      frequency_number: inspection.frequency_number,
      color: inspection.color || this.generateRandomColor()

    }).subscribe(response => {
      this.inspectionSaveBtnOptions.active = false;
      this.close();

    })
  }

  generateRandomColor() {
    var randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
    return randomColor;
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  onTabClick(index) {
    this.tab = index;
  }

  openAssetOwnerTransferPanel() {
    if (this.assetOwner.id != this.backend.currentUser.uid) {
      return;
    }
    let members = [];
    this.projects.forEach(project => {
      const peoples = Object.keys(project.people)
      peoples.forEach(member => {
        if (member != this.assetOwner.id) {
          members.push(member);
        }
      });
    });
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
    members = members.filter(onlyUnique);
    if (members.length === 0) {
      this.toast.warning("No project members added yet");
      return;
    }
    this.backend.getUsers(members).subscribe((users) => {
      this.assetTransferDialogRef = this.dialog.open(this.assetTransferDialog, {
        data: { members: users, member: '' }
      });
    })
  }

  changeOwner(memberId) {
    this.backend.changeAssetOwner(this.form.value.id, memberId).subscribe(response => {
      this.form.get('createdBy').setValue(memberId);
      this.verifyUserIsAssetOwner()
      this.assetTransferDialogRef.close();
    })

  }

  deleteAssetPrompt() {
    this.confirmDialog
      .open(
        `Are you sure you want to delete the asset"${this.form.value.assetName}"? You will be loose all inspections data across this asset.`,
        {
          trueButtonTitle: 'Yes',
          falseButtonTitle: 'No'

        }
      )
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.deleteAssetBtnOptions.active = true;
          this.backend.deleteAsset(this.data.id).subscribe((result: any) => {
            this.deleteAssetBtnOptions.active = false;
            this.close();
          }, error => {
            this.deleteAssetBtnOptions.active = false;
            this.toast.error("something went wrong with request");
          });
        }
      });
  }

  changeAssetType(name: string) {
    if (name === 'solar') {
      this.form.get('industry').setValue('Solar Energy');
    }
  }

  labelsChanged = false;
  dxfData;
  saveLabels() {
    const data = this.dxfData.nodes;
    this.createSaveBtnOptions.active = true;
    this.backend.uploadLabelsJson(this.dxfData.id, data).pipe().subscribe((response: any) => {
      this.backend.update2Dlabels$(this.dxfData.id, response.link, this.dxfData.levels).subscribe()
      this.labelsChanged = false;
      this.createSaveBtnOptions.active = false;
      this.toast.success("DXF drawing and labels saved successfully.");
    }, error => {
      this.toast.error("Something issue to store your labels");
      this.createSaveBtnOptions.active = false;
      throw (error);
    });

  }

  saveLabelsWithCancel() {
    const data = this.dxfData.nodes;
    this.createSaveWithCancelBtnOptions.active = true;
    this.backend.uploadLabelsJson(this.dxfData.id, data).pipe().subscribe((response: any) => {
      this.backend.update2Dlabels$(this.dxfData.id, response.link, this.dxfData.levels).subscribe()
      this.labelsChanged = false;
      this.createSaveWithCancelBtnOptions.active = false;
      this.toast.success("DXF drawing and labels saved successfully.");
      this.close();
    }, error => {
      this.toast.error("Something issue to store your labels");
      this.createSaveWithCancelBtnOptions.active = false;
      throw (error);
    });

  }

  cancelSelection() {
    this.labelsChanged = false;
    this.dxfData = null;
  }
  updateDXF(event) {
    this.labelsChanged = true;
    this.dxfData = event;
  }
}

