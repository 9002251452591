import { UiService } from './../services/ui.service';
import { Component, Input, Output, OnChanges, OnDestroy, OnInit, SimpleChanges, EventEmitter, ChangeDetectorRef, AfterViewChecked, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil, tap, map } from 'rxjs/operators';
import { BackendService } from '../services/backend.service';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { IImageGroupDialogData, ImageGroupDialogComponent } from '../image-group-dialog/image-group-dialog.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageDetailDialogComponent } from '../image-detail-dialog/image-detail-dialog.component';
import { DrawingMode } from '../tagging/tagging.helpers';
import { ImageTitlesDialogComponent } from '../image-titles-dialog/image-titles-dialog.component';
import { KonvaConfig } from '../models/tagging.interface';
import { MatSelectChange } from '@angular/material/select';
import { Project } from '../projects/item/project.type';
import { GeneralService } from '../services/general.service';
import { TagFilterComponent } from '../tagging/tag-filter/tag-filter.component';
import { LinkLabelsComponent } from '../tagging/link-labels/link-labels.component';
import { AddEditScopeComponent } from '../add-edit-scope/add-edit-scope.component';
@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {
  @ViewChild('baseModel') baseModelDialog: any;
  @Input() imageId: string;
  @Input() isAIDetection: boolean;
  @Input() isAnnotationVerified;
  @Input() imageName: string;
  @Input() annotationId: string;
  @Input() isImageSessionLoading:boolean;
  @Input() relationsWithBaseImagesCount: number;
  @Output() drawingChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() zoomChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() techDrawingMode: EventEmitter<any> = new EventEmitter<boolean>();
  @Output() sliderChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() tagFilter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() maskChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isZoomIn: boolean = false;
  public assignGroups = [];
  public selectedSubscription: Subscription = new Subscription();
  public projects$: Observable<Project[]>;
  set taggingMode(value) {
    this.uiService.taggingMode = value;
  }
  public isTemperature: boolean = false;
  public isColorImage: boolean = true;
  get isMask() {
    return this.uiService.isMask;
  }
  set isMask(value) {
    this.uiService.isMask = value;
  }
  @Input() get taggingMode(): DrawingMode {
    return this.uiService.taggingMode;
  }

  @Input() set selectedImage(imageId: string) {
    if (imageId && this.projectId) {
      this.backendService.getImageGroup(this.projectId, imageId).subscribe((resp: any) => {
        this.assignGroups = resp.docs.map(o => o.data()).map(o => o.id)
      })
    }
  }


  zoomIn() {
    this.isZoomIn = !this.isZoomIn;
    this.zoomChange.emit(this.isZoomIn);
  }

  technicalDrawing() {
    this.techDrawingMode.emit();
  }

  tagingModeChange() {
    this.drawingChange.emit();
  }
  public groups = [];
  public selectedGroupId: string = null;
  public selectedGroupName: string = null;

  @Input() public groups$;
  public hasGroups: boolean;

  public get projectId(): string {
    return this.uiService.selectedProjectId;
  }

  get project(): Project {
    return this.uiService.project;
  }

  get activeFolder(): any {
    return this.uiService.activeFolder;
  }

  set activeFolder(value) {
    this.uiService.activeFolder = value;
  }

  public set selectedTag(value) {
    this.uiService.selectedTag = value;
  }

  public get selectedTag(): string {
    return this.uiService.selectedTag;
  }

  public set verifiedTag(value) {
    this.uiService.verifiedTag = value;
  }

  public get verifiedTag(): string {
    return this.uiService.verifiedTag;
  }

  get drawingEnabled$(): BehaviorSubject<boolean> {
    return this.uiService.addContextEnabled$;
  }

  get thermalDrawingEnabled$(): BehaviorSubject<boolean> {
    return this.uiService.thermalEnabled$;
  }


  public showTickIcon$ = new BehaviorSubject<boolean>(false);
  private onDestroy$ = new Subject();
  public isVerticalSlider = false;
  constructor(
    public backendService: BackendService,
    public uiService: UiService,
    public dialog: MatDialog,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private generalService: GeneralService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.matIconRegistry.addSvgIcon(
      `rectangle`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/rectangle.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `polygon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/polygon.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `relate_to`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/relate_to.svg'
      )
    );
  }

  ngOnInit(): void {
    this.uiService.addContextEnabled$.next(false);
    this.uiService.addContextEvent$.pipe(takeUntil(this.onDestroy$)).subscribe((area: KonvaConfig) => {
      this.showImageDetail(area);
    });
    if (this.uiService.project?.projectType === 'thermal') {
      this.isTemperature = true;
      this.drawingEnabled$.next(false);
      this.uiService.thermalEnabled$.next(true);
      if(this.uiService.project?.colorPalette){
        this.isColorImage=true;
        this.uiService.colorImageEnabled$.next(this.isColorImage);
      }else{
        this.isColorImage=false;
      }
    }else{
      this.isTemperature = false;
      this.uiService.thermalEnabled$.next(false);
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.groups$ && changes.groups$.currentValue) {
      this.groups$.pipe(
        tap((r: any) => this.hasGroups = !!r.length),
        tap((groups: any[]) => this.groups = groups),
        takeUntil(this.onDestroy$)
      ).subscribe();

    }
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }


  goTo(link: string) {
    this.router.navigate([link]);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.selectedSubscription.unsubscribe();
  }

  addLabels() {
    const data = {
      projectId: this.projectId,
      assetId: this.uiService.asset.id,
      model: this.uiService.asset.baseModel,
      imageId: this.imageId
    };
    this.dialog.open(LinkLabelsComponent, {
      width: '20vw',
      height: '100vh',
      panelClass: 'top-mr-dialog',
      data,
    });
  }

  filter() {
    const data = {
      projectId: this.projectId
    };
    const dialogRef = this.dialog.open(TagFilterComponent, {
      width: '20vw',
      panelClass: 'top-mr-dialog',
      data,
    });
    dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).pipe().
      subscribe(data => {
        if (data) {
          this.tagFilter.emit(data);
        }
      })
  }

  changeMask(value) {
    this.maskChange.emit()
  }

  manageGroup(): void {
    const data: IImageGroupDialogData = {
      groups$: this.groups$,
      projectId: this.projectId
    };
    const dialogRef = this.dialog.open(ImageGroupDialogComponent, {
      width: '50vw',
      height: '100vh',
      panelClass: 'no-border-radius-dialog',
      data,
    });
  }

  addGroups(): void {
    if (this.selectedGroupId === null || this.groups.length === 0) {
      return;
    }
    const selectedGroup = this.groups.find(group => group.id === this.selectedGroupId);
    this.backendService.addHistory([this.imageId], this.projectId, `Image assigned to group  ${selectedGroup.groupName}`, "group", selectedGroup)
    this.backendService.updateImageGroup$(selectedGroup, [this.imageId], this.projectId).pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {
        this.backendService.updateVerifiedImageAnnotation(this.imageId).subscribe(() => { });
        // this.showTickIcon$.next(true);
        this.selectedGroupId = null;
        //  setTimeout(() => this.showTickIcon$.next(false), 2000);
      });
  }

  deleteImagesFromGroup(): void {
    const selectedGroupObj = this.groups.find(group => group.id === this.selectedGroupId);
    this.backendService.addHistory([this.imageId], this.projectId, `image unassigned from group ${selectedGroupObj.groupName}`, "group", selectedGroupObj)
    this.backendService.removeImagesGroup$(selectedGroupObj, [this.imageId], this.projectId).subscribe(() => {
      this.selectedGroupId = null;
    });
  }

  manageTitles(type: string = 'image'): void {
    const dialogRef = this.dialog.open(ImageTitlesDialogComponent, {
      width: '50vw',
      height: '100vh',
      panelClass: 'no-border-radius-dialog',
      data: {
        type,
        projectId: this.projectId,
        imageId: this.imageId
      },
    });
  }

  showImageDetail(area: KonvaConfig): void {
    const dialogRef = this.dialog.open(ImageDetailDialogComponent, {
      width: '70vw',
      height: '100vh',
      panelClass: 'no-border-radius-dialog',
      disableClose: true,
      data: {
        imageId: this.imageId,
        groups$: this.groups$,
        projectId: this.projectId,
        area,
        annotationId: this.annotationId
      },
    });
  }

  public drawingEnabledToggle(): void {
    const newVal = !this.drawingEnabled$.value;
    if (newVal) {
      this.isTemperature = false;
      this.uiService.thermalEnabled$.next(false);
    }
    this.uiService.addContextEnabled$.next(newVal);
  }

  public thermalDrawingEnabledToggle(): void {
    this.isTemperature = !this.isTemperature;
    if (this.isTemperature) {
      this.drawingEnabled$.next(false);
    }
    this.uiService.thermalEnabled$.next(this.isTemperature);
  }

  public colorImageToggle(): void {
    this.isColorImage = !this.isColorImage;
    this.uiService.colorImageEnabled$.next(this.isColorImage);
  }


  public setSelectedGroupName({ value }: MatSelectChange) {
    const group = this.groups.find(item => item.id === value);
    this.selectedGroupName = group ? group.groupName : null;
  }

  selectProjectRoot(event): void {
    this.clearFilters();
    event.stopImmediatePropagation();
    this.router.navigate([`/dashboard/projects/${this.projectId}`]);
  }

  clearFilters() {
    this.verifiedTag = 'All';
    this.selectedTag = 'All';
    this.uiService.tagSelectedEvent$.next();
  }

  getBreadcrumbs(): string[] {
    return this.activeFolder?.data.breadcrumb.name.split('/');
  }
  public get tree(): any {
    return this.uiService.tree;
  }

  goToBreadcrumb(index: number): void {
    const breadcrumbs = this.getBreadcrumbs();
    if (this.tree) {
      let doc = this.tree.treeModel.nodes.find(
        (node) => node.name === breadcrumbs[0]
      );
      for (let i = 1; i <= index; i++) {
        doc = doc.children.find((child) => child.name === breadcrumbs[i]);
      }
      this.activeFolder = this.tree.treeModel.getNodeById(doc.id);

    }
  }

  getReadOnlyForCurrentUser(): boolean {
    return this.generalService.getReadOnlyForCurrentUser() || this.generalService.AIOverrideProcess();
  }

  sliderView() {
    this.isVerticalSlider = !this.isVerticalSlider;
    this.sliderChange.emit(this.isVerticalSlider)

  }

  public selected(selectedGroup: any): void {
    this.selectedGroupId = selectedGroup.id;
    this.addGroups();
  }

  public removed(selectedGroup: any): void {
    this.selectedGroupId = selectedGroup.value.id;
    this.deleteImagesFromGroup();
  }

  openAsset() {
    this.uiService.isAssetPanelExpand = !this.uiService.isAssetPanelExpand;
  }
  scope() {
    this.dialog.open(AddEditScopeComponent, {
      width: '80%',
      height: '80%',
      data: { project: this.uiService.project }
    });
  }

  changeProject(id) {
    this.uiService.selectedProjectId = id;
    this.uiService.changeProjectEvent$.next();
  }

  verifyAnnotation() {
    return this.backendService.updateVerifiedImageAnnotation(this.imageId).subscribe(() => { });
  }

  close() {
    this.router.navigateByUrl(`dashboard/projects/${this.uiService.selectedProjectId}`)
  }

  dialogRef;
  open2DModel(){
    const data = {
      assetId: this.uiService.asset.id,
      projects: [],
      isAssetOwner: false,
      asset: this.uiService.asset,
      panelsVisible:true,
      leftImageId:this.imageId
    };
    if (this.uiService.asset?.baseModel) {
      this.dialogRef = this.dialog.open(this.baseModelDialog, {
        width: '100%',
        height: '100vh',
        panelClass: 'report-panel',
        data,
      });
      
    }
}

}
